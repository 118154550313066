import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import React from 'react';

import './Lang.scss';

const HeaderLang = ({ className }) => {
	let { pathname } = useLocation();
	pathname = pathname.replace('/en', '');
	return (
		<div className={`HeaderLang ${className}`}>
			<Link to={pathname} className="french">FR</Link>
			<Link to={`/en${pathname}`} className="english">EN</Link>
		</div>
	);
};

HeaderLang.propTypes = {
	className: PropTypes.string,
};

HeaderLang.defaultProps = {
	className: '',
};


export default React.memo(HeaderLang);
