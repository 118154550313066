import {
	GET_LOCATIONS,
} from '../actions/location';


const defaultState = [];

export default (state = defaultState, action) => {
	let newState;
	switch (action.type) {
	case GET_LOCATIONS:
		newState = action.locations;
		return newState;
	default:
		return state;
	}
};
