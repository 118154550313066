import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import {
	Col, Row, Button,
} from 'reactstrap';

import './List-item.scss';


class PropertyListItem extends PureComponent {
	render() {
		let {
			description,
		} = this.props;

		const {
			img,
			localisation,
			maxRoom,
			minRoom,
			name,
			uuid,
		} = this.props;

		let roomText = '';
		if (minRoom < maxRoom) {
			roomText = `${minRoom}-${maxRoom} pièces`;
		} else {
			roomText = `${minRoom} pièces`;
			if (minRoom === 1) {
				roomText.substr(0, roomText.length - 1);
			}
		}

		description = `${description.substr(0, 250)} ...`;

		return (
			<Row className="PropertyListItem bg-info no-gutters my-5">
				<Col md="3">
					<img src={img} alt={name} />
				</Col>
				<Col md="4" className="gold d-flex flex-column align-items-start justify-content-between p-3 p-lg-4 pr-lg-5 ">
					<div className="title">
						<h5>{localisation}</h5>
						<h4>{name}</h4>
					</div>
					<div className="pieces">
						<p className="m-0">{roomText}</p>
					</div>

				</Col>
				<Col
					md="5"
					className="d-flex flex-column align-items-start justify-content-between pb-4 px-4 pt-0 py-lg-4 pr-lg-4"
				>
					<div className="description">
						<p>
							{description}
						</p>
					</div>
					<div className="text-right ml-auto">
						<NavLink to={`/bien/${name}/p/${uuid}`}>
							<Button type="button" className="py-2 px-4">Voir le bien</Button>
						</NavLink>
					</div>
				</Col>
			</Row>
		);
	}
}

PropertyListItem.propTypes = {
	// history: PropTypes.shape({
	// 	push: PropTypes.func.isRequired,
	// }).isRequired,
	description: PropTypes.string.isRequired,
	img: PropTypes.string.isRequired,
	localisation: PropTypes.string.isRequired,
	maxRoom: PropTypes.number.isRequired,
	minRoom: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	uuid: PropTypes.string.isRequired,
};

export default PropertyListItem;
