import PropTypes from 'prop-types';
import React from 'react';

import './Screen.scss';

const Screen = ({ children, className }) => (
	<section className={`Screen ${className}`}>
		{children}
	</section>
);

Screen.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};

Screen.defaultProps = {
	className: '',
};

export default React.memo(Screen);
