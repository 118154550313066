/* eslint-disable max-len */
import React from 'react';
import {
	FormattedMessage as FM,
} from 'react-intl';

// import IndexToolTip from '../../LegalNote/IndexToolTip/IndexToolTip';

// import imgGerard from '../../../images/gerard.jpg';
// import imgGerardP from '../../../images/gerard.webp';
import imgStephanie from '../../../images/stephanie.jpg';
import imgStephanieP from '../../../images/stephanie.webp';

// import imgPropertyByGF1 from '../../../images/partners/gf-1.jpg';
// import imgPropertyByGF2 from '../../../images/partners/gf-2.jpg';
// import imgPropertyByGF3 from '../../../images/partners/gf-3.jpg';
// import imgPropertyByGF1P from '../../../images/partners/gf-1.webp';
// import imgPropertyByGF2P from '../../../images/partners/gf-2.webp';
// import imgPropertyByGF3P from '../../../images/partners/gf-3.webp';

import imgPropertyBySC1 from '../../../images/partners/sc-1.jpg';
import imgPropertyBySC2 from '../../../images/partners/sc-2.jpg';
import imgPropertyBySC3 from '../../../images/partners/sc-3.jpg';
import imgPropertyBySC1P from '../../../images/partners/sc-1.webp';
import imgPropertyBySC2P from '../../../images/partners/sc-2.webp';
import imgPropertyBySC3P from '../../../images/partners/sc-3.webp';

export default {
	stephanie: {
		id: 'stephanie',
		name: 'Stéphanie Coutas',
		job: () => 'architecte',
		descriptionShort: () => (
			<FM
				id="partners.data.stephanie.descriptionShort"
				defaultMessage="Cet architecte d’intérieur et décoratrice de renommée mondiale, révolutionne l’immobilier de luxe en créant de véritables œuvres d’art."
			/>
		),
		descriptionLong: (
			<>
				<FM
					id="partners.data.stephanie.descriptionLong"
					defaultMessage="<p>De son enfance à Hong-Kong, l’architecte d’intérieur et décoratrice Stéphanie Coutas a conservé une appétence intuitive pour le métissage des cultures et le raffinement d’un art de vivre haut de gamme, dénué de toute ostentation.</p> <p>Ses réalisations sophistiquées et luxueuses se distinguent par la noblesse des matériaux, le prestige de mobilier sur-mesure, l’extrême qualité et l’originalité des finitions.</p><p>Un style néoclassique et contemporain qui revisite avec une audace assumée toutes les époques et les courants des arts décoratifs.</p>"
					values={{
						p: (...chunks) => <p>{chunks}</p>,
					}}
				/>
			</>),
		photo: imgStephanie,
		photoWebp: imgStephanieP,
		properties: [
			{ src: imgPropertyBySC1, webp: imgPropertyBySC1P, altText: 'Bien d\'exception 1' },
			{ src: imgPropertyBySC2, webp: imgPropertyBySC2P, altText: 'Bien d\'exception 2' },
			{ src: imgPropertyBySC3, webp: imgPropertyBySC3P, altText: 'Bien d\'exception 3' },
		],
	},
	/*
	gerard: {
		id: 'gerard',
		name: 'Gérard Faivre',
		job: (idx) => (<>Art home maker <IndexToolTip num={`${idx}`} id="art home maker" /></>),
		descriptionShort: (idx) => (
			<FM
				id="partners.data.gerard.descriptionShort"
				defaultMessage={'Porté par la vision de son créateur, GÉRARD FAIVRE métamorphose appartements'
				+ 'parisiens et maisons de luxe en véritables œuvres d’art prêtes à être habitées. '
				+ 'Confort, luxe et originalité, cette révolution a un nom : Art Homes{tip}.'}
				values={{
					tip: <IndexToolTip num={`${idx}`} id="art homes" />,
				}}
			/>
		),
		descriptionLong: (
			<>
				<FM
					id="partners.data.gerard.descriptionLong"
					defaultMessage={'<p>Depuis toujours, Gérard Faivre est passionné par la décoration et le design, '
					+ 'une passion qui l’a porté tout au long de ses études d’architecture et de sa carrière dans le'
					+ ' prêt-à-porter et qui a fini par le mener à imaginer ce qui restera sa plus belle création : '
					+ 'son propre métier, Art Home Maker{tipa}.</p><p>C’est après un séjour dans le Sud de la France que l’aventure'
					+ ' a commencé. Ebloui par la beauté de la Provence, il décide d’acheter et de rénover un'
					+ ' vieux mas, une ferme typique de la région. Une première expérience qui sera un véritable'
					+ ' déclic, Art Homes{tip} était né.</p><p>La démarche est aussi simple que novatrice :'
					+ ' acheter une demeure de prestige qui l’inspire, la rénover, la moderniser et la décorer'
					+ ' avant de retrouver sa place sur le marché de l’immobilier de luxe. Gérard Faivre peut'
					+ ' ainsi exploiter l’ensemble de ses savoir-faire à Paris, dans les Alpes ou sur la Côte'
					+ ' d’Azur, notamment en matière d’immobilier de prestige, d’architecture et de décoration'
					+ ' pour créer des biens d’exception.</p>'}
					values={{
						p: (...chunks) => <p>{chunks}</p>,
						tipa: <IndexToolTip num="1" id="art home maker" />,
						tip: <IndexToolTip num="2" id="art homes" />,
					}}
				/>

			</>
		),
		photo: imgGerard,
		photoWebp: imgGerardP,
		properties: [
			{ src: imgPropertyByGF1, webp: imgPropertyByGF1P, altText: 'Bien d\'exception 1' },
			{ src: imgPropertyByGF2, webp: imgPropertyByGF2P, altText: 'Bien d\'exception 2' },
			{ src: imgPropertyByGF3, webp: imgPropertyByGF3P, altText: 'Bien d\'exception 3' },
		],
	},*/
};
