import PropTypes from 'prop-types';
import React from 'react';

import PropertyItem from './List-item';

import './List.scss';

const { REACT_APP_AWS_S3_URL } = process.env;

const PropertyList = ({ properties }) => (
	<div className="PropertyList">
		{
			properties.length === 0
				? (
					<p className="p-5">
						Aucun résultat
					</p>
				)
				: properties.map(p => (
					<PropertyItem
						key={p.id}
						description={p.description}
						img={`${REACT_APP_AWS_S3_URL}${p.files[0].name}`}
						localisation={(p.location && p.location.label) || ''}
						name={p.name}
						minRoom={p.minRooms}
						maxRoom={p.maxRooms}
						uuid={p.uuid}
					/>
				))
		}
	</div>
);

PropertyList.propTypes = {
	properties: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default React.memo(PropertyList);
