import Helmet from 'react-helmet';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';

import LegalMentions from '../../components/Legal/Mentions';
import Screen from '../../components/Screen/Screen';

import './Mentions-legales.scss';

const {
	REACT_APP_EMAIL_PUBLIC,
	REACT_APP_WWW,
} = process.env;


export default () => (
	<Screen className="MentionsLegales">
		<Helmet>
			<title>Luxury properties – Mentions Légales</title>
		</Helmet>
		<Container className="mb-5 pb-5">
			<Row>
				<Col>
					<LegalMentions appWWW={REACT_APP_WWW} appEmailPublic={REACT_APP_EMAIL_PUBLIC} />
				</Col>
			</Row>
		</Container>

	</Screen>
);
