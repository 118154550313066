import {
	FormattedMessage as FM, useIntl,
} from 'react-intl';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import {
	Button,
	Container,
	Row,
} from 'reactstrap';
import { NavLink as RRNavLink } from 'react-router-dom';

import './Contact.scss';

const ScreenSectionContact = ({ className }) => {
	const { trackEvent } = useMatomo();
	const intl = useIntl();
	const isEn = intl.locale === 'en' ? '/en' : '';

	const handleClickContactButton = useCallback(() => {
		trackEvent({
			category: 'Contact',
			action: 'Click',
			value: 'CTA_CLICK_CONTACT',
		});
	}, [trackEvent]);

	return (
		<Container className="ScreenSectionContactContainer m-0 mx-md-auto">
			<Row className="m-0">
				<div className={`ScreenSectionContact justify-content-center align-items-center d-flex ${className}`}>
					<div className="box-text">
						<div
							className="m-0 d-flex justify-content-between align-items-center flex-md-row flex-column"
						>
							<div className="text-uppercase">
								<FM
									id="cta.contact.h2"
									tagName="h2"
									defaultMessage="un projet ou une question ?"
								/>
								<FM
									id="cta.contact.text"
									defaultMessage="contactez nous pour obtenir des {br} informations complémentaires"
									values={{
										br: <br />,
									}}
								/>
							</div>
							<Button
								className="outline-btn mt-5 mt-md-0"
								tag={RRNavLink}
								to={`${isEn}/contact`}
								onClick={handleClickContactButton}
							>
								<FM
									id="cta.contact.button"
									defaultMessage="Nous contacter"
								/>
							</Button>
						</div>
					</div>
				</div>
			</Row>
		</Container>
	);
};

ScreenSectionContact.propTypes = {
	className: PropTypes.string,
};
ScreenSectionContact.defaultProps = {
	className: 'contactBg',
};

export default React.memo(ScreenSectionContact);
