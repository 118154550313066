import PropTypes from 'prop-types';
import React from 'react';

import './Image.scss';

const ScreenSectionImage = ({ src }) => (
	<div className="ScreenSectionImage rel">
		{ src
			&& <div className="bg" style={{ backgroundImage: `url('${src}')` }} />
		}
	</div>
);

ScreenSectionImage.propTypes = {
	src: PropTypes.string,
};

ScreenSectionImage.defaultProps = {
	src: PropTypes.string,
};

export default React.memo(ScreenSectionImage);
