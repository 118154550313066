import {
	SEARCH_PROGRAMME_DEBUT_ACTION,
	SEARCH_PROGRAMME_RESULT_ACTION,
	GET_CURRENT_PROGRAMME_ACTION,
	GET_LAST_PROGRAMMES_ACTION,
	GET_OTHER_PROGRAMMES_ACTION,
	GET_RANDOM_PROGRAMMES_ACTION,
} from '../actions/programme';

const programmes = [];
const programmesSearched = [];
const currentProgramme = {};
const lastProgrammes = [];
const randomProgrammes = [];
const otherProgrammes = [];

const defaultState = {
	programmes,
	programmesSearched,
	currentProgramme,
	lastProgrammes,
	randomProgrammes,
	otherProgrammes,
	searching: false,
};

const programme = (state = defaultState, action) => {
	switch (action.type) {
	case SEARCH_PROGRAMME_DEBUT_ACTION:
		return Object.assign({}, state, { searching: true });
	case SEARCH_PROGRAMME_RESULT_ACTION:
		return Object.assign({}, state, {
			programmesSearched: action.programmes,
			searching: false,
		});
	case GET_CURRENT_PROGRAMME_ACTION:
		return Object.assign({}, state, { currentProgramme: action.programme });
	case GET_LAST_PROGRAMMES_ACTION:
		return Object.assign({}, state, { lastProgrammes: action.programmes });
	case GET_RANDOM_PROGRAMMES_ACTION:
		return Object.assign({}, state, { randomProgrammes: action.programmes });
	case GET_OTHER_PROGRAMMES_ACTION:
		return Object.assign({}, state, { otherProgrammes: action.programmes });
	default:
		return state;
	}
};

export default programme;
