import {
	FormattedMessage as FM,
} from 'react-intl';
import React from 'react';
import { NavLink } from 'react-router-dom';
import {
	Button, Col, Row,
} from 'reactstrap';

import Image from '@cecaz-immo/ui/dist/components/image/image';

import ScreenSection from '../ScreenSection';
import ScreenHeaderImage from '../../Header/Image/Image';

import './PartnersPresentation.scss';

import partnersData from '../../Partners/partners.data';

// const { gerard } = partnersData;
const { stephanie } = partnersData;

const ScreenSectionArtMakers = () => (
	<>
		<ScreenHeaderImage className="bgSalon" />
		<ScreenSection className="ScreenSectionArtMakers">
			<Row className="pt-4 pt-md-0 justify-content-md-center">
				<Col xs="12" md="10" lg="8" className="bg-beige">
					<Row>
						<Col md="auto" className="p-0">
							<Image className="d-block mx-auto" alt="Stéphanie Coutas" src={stephanie.photo} webp={stephanie.photoWebp} />
						</Col>
						<Col className="name bg-secondary d-flex flex-column justify-content-around flex-grow-0 flex-md-grow-1">
							<div className="titre mx-4">
								<h2>{stephanie.name}</h2>
							</div>
							<div className="white-line mx-4 text-left">
								<span className="text-uppercase">{stephanie.job()}</span>
							</div>
						</Col>
					</Row>
					<Row className="description bg-beige py-5">
						<Col md="12" className="text-secondary">
							{stephanie.descriptionShort()}
						</Col>
					</Row>
				</Col>

				{/* gerard*/}

				{/* <Col className="col-12 offset-0 col-md-8 offset-md-2 col-lg-6 offset-lg-0">
					<Row>
						<Col md="6" className="p-0">
							<Image
								className="d-block
								mx-auto" alt="Gérard Faivre" src={gerard.photo} webp={gerard.photoWebp}
							/>
						</Col>
						<Col md="6" className="name bg-secondary d-flex flex-column justify-content-around">
							<div className="titre mx-4">
								<h2>{gerard.name}</h2>
							</div>
							<div className="white-line mx-4 text-left">
								<span className="text-uppercase">{gerard.job(5)}</span>
							</div>
						</Col>
					</Row>
					<Row className="description bg-beige py-5">
						<Col md="12" className="text-secondary">
							{gerard.descriptionShort(6)}
						</Col>
					</Row>
				</Col> */}
			</Row>
			<Row className="justify-content-center">
				<Col xs="12" md="10" lg="8" className="bg-beige text-center pb-5">
					<Button className="button rounded-0" tag={NavLink} to="/nos-partenaires/stephanie">
						<FM
							id="button.partners"
							defaultMessage="Découvrez nos partenaires"
						/>
					</Button>
				</Col>
			</Row>
		</ScreenSection>
	</>
);

export default React.memo(ScreenSectionArtMakers);
