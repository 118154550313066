import React from 'react';
import {
	BrowserRouter,
} from 'react-router-dom';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';

// redux
import { createLogger } from 'redux-logger';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk';

import Reducers from './store/reducers';

import { Routes } from './Routes';

/* Redux */
const logger = createLogger({
	// predicate: (getState, action) => action.type !== '',
	collapsed: (getState, action) => action.type !== '',
});

let middleware = [thunkMiddleware];

if (process.env.NODE_ENV !== 'production') {
	middleware = [...middleware, logger];
}

const store = createStore(Reducers, applyMiddleware(...middleware));

const { REACT_APP_MATOMO_URL, REACT_APP_MATOMO_SITE_ID } = process.env;

const matomoInstance = createInstance({
	urlBase: REACT_APP_MATOMO_URL,
	siteId: REACT_APP_MATOMO_SITE_ID,
	trackerName: 'matomo',
});

const App = () => (
	<MatomoProvider value={matomoInstance}>
		<Provider store={store}>
			<BrowserRouter
				basename={process.env.PUBLIC_URL}
			>
				<Routes />
			</BrowserRouter>
		</Provider>
	</MatomoProvider>
);

export default App;
