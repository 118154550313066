
import { FormattedMessage as FM, useIntl } from 'react-intl';
import Helmet from 'react-helmet';
import React from 'react';
import { Row } from 'reactstrap';
import { NavLink } from 'react-router-dom';

import Screen from '../components/Screen/Screen';
import ScreenSection from '../components/Screen/Section/ScreenSection';
import ScreenSectionTitle from '../components/Screen/Section/Title/Title';
import ScreenHeader from '../components/Screen/Header/ScreenHeader';
import ScreenHeaderTitle from '../components/Screen/Header/Title/Title';
import ScreenHeaderImage from '../components/Screen/Header/Image/Image';

import ScreenSectionForYouLinks from '../components/Screen/Section/ForYouLinks/ForYouLinks';
import ScreenSectionContact from '../components/Screen/Section/Contact/Contact';
import ScreenSectionSubtitle from '../components/Screen/Section/Subtitle/Subtitle';

import ScreenColLeft from '../components/Screen/Col/Left';
import ScreenColRight from '../components/Screen/Col/Right';
import IndexToolTip from '../components/LegalNote/IndexToolTip/IndexToolTip';
import AsteriskFooter from '../components/LegalNote/AsteriskFooter/AsteriskFooter';


import './Future-owners.scss';

const FutureOwner = () => {
	const intl = useIntl();
	return (
		<Screen className="FutureOwner">
			<Helmet>
				<title>Luxury properties – Futurs propriétaires</title>
			</Helmet>
			<ScreenHeader>
				<ScreenHeaderImage className="futureOwners" />
				<ScreenHeaderTitle
					colLeft={(
						<FM
							id="futureOwners.title"
							defaultMessage="Concevoir{br}un projet d&apos;investissement{br}dans l&apos;immobilier de{br}<strong>luxe</strong>"
							values={{
								br: <br />,
								strong: (...chunks) => <strong>{chunks}</strong>,
							}}
						/>
					)}
					colRight={(
						<FM
							id="futureOwners.subtitle"
							tagName="p"
							defaultMessage="Les équipes et les partenaires du Centre d’Affaires Luxury Properties ont été choisis pour leur expérience de l'immobilier haut de gamme."
						/>
					)}
				/>
			</ScreenHeader>
			<ScreenSection>
				<ScreenSectionTitle>
					<FM
						id="futureOwners.foryou.title"
						defaultMessage="<span>pour vous, futurs propriétaires d’une propriété de luxe :</span>"
						values={{
							span: (...chunks) => <span className="text-uppercase">{chunks}</span>,
						}}
					/>
				</ScreenSectionTitle>
				<Row className="row justify-content-around text-justify">
					<ScreenColLeft>
						<FM
							id="futureOwners.foryou.left-1"
							defaultMessage="<p>Les experts en biens d’exception du Centre d'Affaires Luxury Properties répondent aux attentes d’une clientèle internationale recherchant des biens immobiliers de luxe.</p>"
							values={{
								p: (...chunks) => <p className="border-bottom border-secondary pb-4">{chunks}</p>,
							}}
						/>
						<FM
							id="futureOwners.foryou.left-2"
							defaultMessage="<p><strong>NOS SERVICES EXTRA BANCAIRES :</strong>{br}Ces professionnels de l'immobilier de luxe sont là pour vous conseiller sur des besoins spécifiques.</p>"
							values={{
								p: (...chunks) => <p className="infoLeft2 pt-4">{chunks}</p>,
								strong: (...chunks) => <strong>{chunks}</strong>,
								br: <br />,
							}}
						/>

					</ScreenColLeft>
					<ScreenColRight>
						<ScreenSectionSubtitle>
							<FM
								id="futureOwners.foryou.right-1"
								defaultMessage="&gt; Des services prestigieux pour votre future demeure de luxe :"
								tagName="h3"
							/>
						</ScreenSectionSubtitle>
						<FM
							id="futureOwners.foryou.right-1.list"
							defaultMessage={
								'<ul>'
								+ '<li key="f1"><strong>Des crédits personnalisés{tip}</strong> pour votre projet d’immobilier de luxe, adaptés à votre situation et à vos objectifs.</li>'
								+ '<li key="f2"><strong>Des placements sur-mesure{tip2}</strong> proposés en partenariat avec Natixis Bank au Luxembourg pour financer votre future propriété de prestige.</li>'
								+ '</ul>'
							}
							values={{
								ul: (...chunks) => <ul>{chunks}</ul>,
								li: (...chunks) => <li>{chunks}</li>,
								strong: (...chunks) => <strong>{chunks}</strong>,
								tip: <IndexToolTip num="1" id="crédits personnalisés" />,
								tip2: <IndexToolTip num="2" id="placements sur-mesure" />,
							}}
						/>
						<ScreenSectionSubtitle>
							<FM
								id="futureOwners.foryou.right-2"
								defaultMessage="&gt; Un projet d&apos;acquisition immobilière de prestige nécessitant un financement ?"
								tagName="h3"
							/>
						</ScreenSectionSubtitle>
						<p>
							<NavLink to={`${intl.locale === 'en' ? '/en/' : '/'}contact`}>
								<FM
									id="futureOwners.foryou.contact"
									defaultMessage="Contactez nos experts"
								/>
							</NavLink>
						</p>
					</ScreenColRight>
				</Row>
			</ScreenSection>
			<ScreenSectionContact />
			<ScreenSectionForYouLinks futur={process.env.REACT_APP_SHOW_PROPERTIES === 'true'} />
			<AsteriskFooter page="futursPropietaire" />
		</Screen>
	);
};

export default FutureOwner;
