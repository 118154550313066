import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import {
	Col,
	Row,
} from 'reactstrap';

import ScreenSection from '../Section/ScreenSection';
import partnersData from './partners.data';

import './Menu.scss';

const ScreenPartnersMenu = ({ partner }) => (
	<ScreenSection className="submenu">
		<Row className="flex-container">
			{
				Object.keys(partnersData).map((k) => (
					<Col
						className={partner === k ? 'selected pt-3' : 'unselected pt-3'}
						//onClick={() => this.toggleClass('stephanie')}
						md="3"
						tag={Link}
						to={`/nos-partenaires/${partnersData[k].id}`}
						key={partnersData[k].id}
					>
						{partnersData[k].name}
					</Col>
				))
			}
		</Row>
	</ScreenSection>
);

ScreenPartnersMenu.propTypes = {
	partner: PropTypes.string.isRequired,
};

export default React.memo(ScreenPartnersMenu);
