import {
	FormattedMessage as FM,
} from 'react-intl';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import Image from '@cecaz-immo/ui/dist/components/image/image';
import ScreenSection from '../ScreenSection';

import './FutursAndProLinks.scss';

import imgFuturOwner from '../../../../images/futurs.png';
import imgGood from '../../../../images/pro.png';
import imgFuturOwnerP from '../../../../images/futurs.webp';
import imgGoodP from '../../../../images/pro.webp';
import IndexToolTip from '../../../LegalNote/IndexToolTip/IndexToolTip';


const ScreenSectionFutursAndProLinks = () => (
	<ScreenSection className="ScreenSectionFutursAndProLinks">
		<Row>
			<Col xs="auto" sm="12" lg="6" className="p-0 pr-lg-2 mx-auto">
				<NavLink
					className="banner d-flex justify-content-between align-items-center flex-column-reverse flex-sm-row my-2 my-lg-0"
					to="/futurs-proprietaires"
				>
					<div className="w-100 p-2">
						<FM
							id="futursAndProLinks.futur.h2"
							defaultMessage="futurs propriétaires"
						>
							{(t) => <h2 className="text-uppercase">{t}</h2>}
						</FM>
						<FM
							id="futursAndProLinks.futur.h3"
							tagName="h3"
							defaultMessage="Financez{tip} votre projet{br}d'immobilier de luxe!"
							values={{
								br: <br />,
								tip: <IndexToolTip num="1" id="financez" />,
							}}
						/>
					</div>
					<Image alt="good" src={imgFuturOwner} webp={imgFuturOwnerP} />
				</NavLink>
			</Col>

			<Col xs="auto" sm="12" lg="6" className="p-0 pl-lg-2 mx-auto">
				<NavLink
					className="banner d-flex justify-content-between align-items-center flex-column flex-sm-row my-2 my-lg-0"
					to="/professionnels-de-l-immobilier"
				>
					<Image alt="good" src={imgGood} webp={imgGoodP} />
					<div className="w-100 p-2 text-right">
						<FM
							id="futursAndProLinks.pro.h2"
							defaultMessage="professionnels de l'immobilier"
						>
							{(t) => <h2 className="text-uppercase">{t}</h2>}
						</FM>
						<FM
							id="futursAndProLinks.pro.h3"
							tagName="h3"
							defaultMessage="Nos solutions de {br} financement{tip}"
							values={{
								br: <br />,
								tip: <IndexToolTip num="2" id="financement" />,
							}}
						/>
					</div>
				</NavLink>
			</Col>
		</Row>
	</ScreenSection>
);

export default React.memo(ScreenSectionFutursAndProLinks);
