import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Screen from '../components/Screen/Screen';

import './404.scss';

const PageNotFound = () => (
	<Screen className="PageNotFound">
		<Helmet>
			<title>luxuryproperties – 404</title>
		</Helmet>
		<Container className="mb-5 py-5">
			<Row>
				<Col>
					<section className="PageNotFound 404 d-flex flex-column justify-content-center align-items-center">
						<h1>Page introuvable</h1>
						<p>
							La page que vous avez demandée n’est pas disponible ou n’existe plus.
						</p>
						<p>
							Veuillez nous excuser pour cet incident.
						</p>
						<Link className="btn btn-secondary rounded-0 mt-2" to="/">
							Retour sur la page d’accueil
						</Link>
					</section>
				</Col>
			</Row>
		</Container>
	</Screen>
);

export default PageNotFound;
