import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { Tooltip } from 'reactstrap';
import { useFormatMessage } from '../../../lib/useFormatMessage';

import { footNoteLegalObj } from '../data';
import './IndexToolTip.scss';

let lastId = 0;

const randId = (prefix = 'id') => {
	lastId += 1;
	return `${prefix}${lastId}`;
};

const IndexToolTip = ({ id, num }) => {
	const [tooltipOpen, setTooltipOpen] = useState(false);
	const target = useMemo(() => randId(`tooltip-${num}`), [num]);
	// eslint-disable-next-line max-len
	const traduction = useFormatMessage(footNoteLegalObj[id] && footNoteLegalObj[id].label.props.id);
	if (!footNoteLegalObj[id]) return null;
	if (traduction === 'none') return null;

	const toggle = () => setTooltipOpen(!tooltipOpen);
	const scrollToRef = (ref) => ref.current.scrollIntoView({
		behavior: 'smooth',
	});
	const handleClickSup = (e) => {
		e.preventDefault();
		e.nativeEvent.stopImmediatePropagation();
		scrollToRef(footNoteLegalObj[id].ref);
		return false;
	};
	return (
		<span className="tipPreventEvent">
			<sup
				className="IndexToolTip tipSup js-smooth-scroll"
				id={target}
				onClick={handleClickSup}
			>
				{num}
			</sup>
			<Tooltip className="IndexToolTip" target={target} placement="top" isOpen={tooltipOpen} autohide={false} toggle={toggle}>
				{footNoteLegalObj[id].label}
			</Tooltip>
		</span>
	);
};

IndexToolTip.propTypes = {
	id: PropTypes.string.isRequired,
	num: PropTypes.string.isRequired,
};

export default React.memo(IndexToolTip);
