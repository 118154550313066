import React, { useEffect } from 'react';
import {
	Route, Switch, useLocation,
} from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react';

// intl
import { IntlProvider } from 'react-intl';

// external components

/* React Intl*/
import locales from './locales';

// components
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import NavBar from './components/Navbar/NavBar';

// Screens
import Cgu from './screens/legal/Cgu';
import Contact from './screens/Contact';
import FutureOwner from './screens/Future-owners';
import Home from './screens/Home';
import MentionsLegales from './screens/legal/Mentions-legales';
import NotFoundRoute from './screens/404';
import Partners from './screens/Partners';
import PolitiqueCookies from './screens/legal/Politique-cookies';
import Property from './screens/Property';
import RealEstateProfessionals from './screens/Real-estate-professionals';
import PropertySearch from './screens/Property-results';

const ScrollToTop = ({ children }) => {
	const { pathname } = useLocation();
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}, [pathname]);

	return children || null;
};

// eslint-disable-next-line import/prefer-default-export
export const Routes = () => {
	const location = useLocation();
	const { trackPageView } = useMatomo();
	useEffect(() => {
		trackPageView({
			documentTitle: location.pathname,
		});
	});

	return (
		<ScrollToTop>
			<main className="app lp">
				<Switch>
					<Route path="/en">
						<IntlProvider locale="en" messages={locales.en}>
							<Header>
								<Switch>
									{/* Use of params.uuid in programme page */}
									<Route
										path="/bien/:name/p/:uuid"
										component={NavBar}
									/>
									<Route component={NavBar} />
								</Switch>
							</Header>
							<Switch>
								<Route exact path="/en/" component={Home} />
								<Route
									path="/en/rechercher"
									component={PropertySearch}
								/>
								<Route
									path="/en/conditions-generales-utilisation"
									component={Cgu}
								/>
								<Route
									path="/en/contact/:uuid?"
									component={Contact}
								/>
								<Route
									path="/en/futurs-proprietaires"
									component={FutureOwner}
								/>
								<Route
									path="/en/mentions-legales"
									component={MentionsLegales}
								/>
								<Route
									path="/en/nos-partenaires/:partner"
									component={Partners}
								/>
								<Route
									path="/en/politique-cookies"
									component={PolitiqueCookies}
								/>
								<Route
									path="/en/professionnels-de-l-immobilier"
									component={RealEstateProfessionals}
								/>
								<Route
									path="/en/property"
									component={Property}
								/>
								<Route
									path="/en/*"
									component={NotFoundRoute}
								/>
							</Switch>
						</IntlProvider>
					</Route>
					<IntlProvider locale="fr" messages={locales.fr}>
						<Header>
							<Switch>
								{/* Use of params.uuid in programme page */}
								<Route
									path="/bien/:name/p/:uuid"
									component={NavBar}
								/>
								<Route component={NavBar} />
							</Switch>
						</Header>
						<Switch>
							<Route exact path="/" component={Home} />
							<Route
								path="/rechercher"
								component={PropertySearch}
							/>
							<Route
								path="/conditions-generales-utilisation"
								component={Cgu}
							/>
							<Route
								path="/contact/:uuid?"
								component={Contact}
							/>
							<Route
								path="/futurs-proprietaires"
								component={FutureOwner}
							/>
							<Route
								path="/mentions-legales"
								component={MentionsLegales}
							/>
							<Route
								path="/nos-partenaires/:partner"
								component={Partners}
							/>
							<Route
								path="/politique-cookies"
								component={PolitiqueCookies}
							/>
							<Route
								path="/professionnels-de-l-immobilier"
								component={RealEstateProfessionals}
							/>
							<Route
								path="/bien/:name/p/:uuid"
								component={Property}
							/>
							<Route path="*" component={NotFoundRoute} />
						</Switch>
					</IntlProvider>
				</Switch>
				<Footer />
			</main>
		</ScrollToTop>
	);
};
