import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import React from 'react';

import Screen from '../components/Screen/Screen';
import ScreenPartnersMenu from '../components/Screen/Partners/Menu';
import ScreenSectionForYouLinks from '../components/Screen/Section/ForYouLinks/ForYouLinks';
import ScreenSectionContact from '../components/Screen/Section/Contact/Contact';
import AsteriskFooter from '../components/LegalNote/AsteriskFooter/AsteriskFooter';

import Partners from '../components/Screen/Partners/Partners';

import './Partners.scss';

const OurPartners = ({ match }) => (
	<Screen>
		<Helmet>
			<title>Luxury properties – Nos partenaires</title>
		</Helmet>
		<div className="bg-info">
			<ScreenPartnersMenu partner={match.params.partner} />
		</div>
		<Partners selectedPartner={match.params.partner} />
		<ScreenSectionContact />
		<ScreenSectionForYouLinks />
		<AsteriskFooter page="partenaires" />
	</Screen>
);

OurPartners.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			partner: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
};

export default React.memo(OurPartners);
