import { combineReducers } from 'redux';
import locations from './location';
import programme from './programme';

const app = combineReducers({
	locations,
	programme,
});

export default app;
