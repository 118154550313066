import { FormattedMessage as FM, defineMessages } from 'react-intl';
import Helmet from 'react-helmet';
import { useRouteMatch } from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import React, {
	useCallback,
	useEffect,
	useState,
} from 'react';
import {
	Button, Col, Row,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import sendContactClient from '@cecaz-immo/app-client/dist/lib/contact';
import constactSchema from '@cecaz-immo/app-client/dist/schemas/contact';

import CheckboxField from '@cecaz-immo/ui/dist/components/checkbox/checkboxField';
import Field from '@cecaz-immo/ui/dist/components/form/field/field';
import Form from '@cecaz-immo/ui/dist/components/form/form';

import { useFormatMessage } from '../lib/useFormatMessage';
import LegalContact from '../components/Legal/Contact';
import Screen from '../components/Screen/Screen';
import ScreenHeaderImage from '../components/Screen/Header/Image/Image';
import ScreenSection from '../components/Screen/Section/ScreenSection';

import './Contact.scss';

const initParams = {
	allowCommercialCecaz: false,
	allowCommercialPartner: false,
	firstName: '',
	lastName: '',
	email: '',
	phone: '',
	financialAdvise: false,
	commercialAdvise: false,
	message: '',
	programmeUuid: null,
};

const Contact = () => {
	const { trackEvent } = useMatomo();

	const match = useRouteMatch();

	const [params, setParams] = useState(initParams);
	const [isSent, setIsSent] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);

	useEffect(() => {
		const uuid = match.params && match.params.uuid;
		setParams((prev) => ({
			...prev,
			programmeUuid: uuid,
			financialAdvise: !uuid,
			commercialAdvise: !!uuid,
		}));
	}, [match]);

	useEffect(() => {
		if (process.env.NODE_ENV === 'development') {
			setParams((prev) => ({
				...prev,
				firstName: 'Corinne',
				lastName: 'Savard',
				email: 'francois.michaudon@53js.fr',
				phone: '0656235623',
				message: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
			}));
		}
	}, []);

	const resetState = useCallback(() => {
		setParams(initParams);
	}, []);

	const handleSubmit = useCallback(async (e) => {
		e.preventDefault();
		setError(false);
		const data = { ...params };
		if (!data.programmeUuid) {
			delete data.programmeUuid;
		}
		setLoading(true);
		try {
			await sendContactClient(data);
			resetState();
			setIsSent(true);
			trackEvent({
				category: 'Contact',
				action: 'Send',
				value: 'CONTACT_CONSEILLER',
			});
		} catch (err) {
			// eslint-disable-next-line no-console
			console.log(err);
			setError(true);
		}
		setLoading(false);
	}, [params, resetState, trackEvent]);

	const handleChange = useCallback((newData) => {
		setParams(newData);
	}, []);

	defineMessages({
		firstName: { id: 'form.firstName', defaultMessage: 'prénom' },
		lastName: { id: 'form.lastName', defaultMessage: 'nom' },
		phone: { id: 'form.phone', defaultMessage: 'téléphone' },
		mail: { id: 'form.mail', defaultMessage: 'email' },
		message: { id: 'form.message', defaultMessage: 'Votre message ...' },
		send: { id: 'form.send', defaultMessage: 'Envoyer' },
	});

	return (
		<Screen>
			<Helmet>
				<title>Luxury properties – Contact</title>
			</Helmet>
			<ScreenHeaderImage className="bgContact" />
			<ScreenSection>
				<Row className="contactHeader">
					<Col className="d-flex align-items-center flex-column bd-highlight">
						<h1 className="mt-5 w-100">
							<FM
								id="contact.title"
								defaultMessage="Contactez un conseiller du Centre d'Affaires{br}Luxury Properties"
								values={{
									br: <br />,
								}}
							/>
						</h1>
						{
							params.commercialAdvise
							&& (
								<span>
									Pour obtenir des informations sur le bien
									immobilier et sur son financement.
								</span>
							)
						}
					</Col>
					<Col className="">
						<h2 className="text-white mt-5 d-block">
							<FM
								id="contact.title2"
								defaultMessage="Contactez directement un conseiller{br}du lundi au vendredi de 9h à 19h"
								values={{
									br: <br />,
								}}
							/>
						</h2>
					</Col>
				</Row>
				<Row className="align-items-start">
					<Col className="form-container">
						<>
							<Form
								className="mb-5"
								data={params}
								schema={constactSchema}
								onSubmit={handleSubmit}
								onChange={handleChange}
							>
								<Field
									component="input"
									type="text"
									name="lastName"
									placeholder={useFormatMessage('form.lastName').capitalize()}
									value={params.lastName}
								/>
								<Field
									component="input"
									type="text"
									name="firstName"
									placeholder={useFormatMessage('form.firstName').capitalize()}
									value={params.firstName}
								/>
								<Field
									component="input"
									type="tel"
									name="phone"
									placeholder={useFormatMessage('form.phone').capitalize()}
									value={params.phone}
									errorMessages={{
										minLength: () => `${params.phone} n'est un numéro valide`,
									}}
								/>
								<Field
									component="input"
									type="email"
									name="email"
									placeholder={useFormatMessage('form.mail').capitalize()}
									value={params.email}
									errorMessages={{
										format: () => `${params.email} n'est pas un email valide`,
									}}
								/>
								<Field
									component="textarea"
									className="mb-4 mt-2"
									rows="8"
									placeholder={useFormatMessage('form.message').capitalize()}
									name="message"
									value={params.message}
								/>

								<CheckboxField name="allowCommercialCecaz" checked={params.allowCommercialCecaz}>
									<FM
										id="contact.allowCommercialCecaz"
										defaultMessage="Si vous souhaitez recevoir les offres commerciales de la Caisse d’Epargne par courrier électronique (mél, SMS, MMS), merci de cocher la case ci contre."
									/>
								</CheckboxField>

								<CheckboxField name="allowCommercialPartner" checked={params.allowCommercialPartner}>
									<FM
										id="contact.allowCommercialPartner"
										defaultMessage="Si vous souhaitez recevoir des offres commerciales de nos partenaires par courrier électronique (mél, SMS, MMS), merci de cocher la case ci contre."
									/>
								</CheckboxField>

								<Button
									className="contactBtn d-block rounded-0 m-auto w-75 py-3 font-weight-bold border border-secondary text-uppercase"
									disabled={loading}
									size="lg"
									type="submit"
								>
									{
										(loading && <FontAwesomeIcon icon={faSpinner} spin />)
										|| (<FM id="form.send" defaultMessage="Envoyer" />)
									}
								</Button>
								{
									isSent && (
										<p className="success text-center mt-5 mb-0">
											<FM id="form.sent" defaultMessage="Votre demande a bien été envoyé !" />
										</p>
									)
								}
								{
									error && (
										<p className="fail text-center mt-5 mb-0">
											Une erreur est survenue, merci de réssayer
											ultérieurement ou d’utiliser notre ligne direct.
										</p>
									)
								}
							</Form>
							<LegalContact />
						</>
					</Col>
					<Col className="bg-primary mb-5 pt-5 ">
						<div className="info">
							<div className="contactCard">
								<div className="p-3">
									<FM
										id="contact.phone"
										defaultMessage="Par téléphone"
									/>
									<small className="d-block">
										<FM
											id="contact.phone.price"
											defaultMessage="Prix d&apos;un appel local"
										/>
									</small>
								</div>
								<div className="p-3 contact">
									<a href="tel:+33489038737">
										04 89 03 87 37
									</a>
								</div>
							</div>

							<div className="contactCard my-5">
								<div className="p-3">
									<FM
										id="contact.mail"
										defaultMessage="Par mail"
									/>
									<small className="d-block">
										<FM
											id="contact.mail.price"
											defaultMessage="Coût de connexion selon votre fournisseur internet"
										/>
									</small>
								</div>
								<div className="p-3 contactMail">
									<a href="mailto:luxury-properties@cecaz.caisse-epargne.fr">luxury-properties@cecaz.caisse-epargne.fr</a>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</ScreenSection>
		</Screen>
	);
};

export default Contact;
