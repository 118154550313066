import { FormattedMessage as FM, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import {
	Col, Row,
} from 'reactstrap';

import Image from '@cecaz-immo/ui/dist/components/image/image';

import ScreenSection from '../ScreenSection';

import './ForYouLinks.scss';

import imgFuturOwner from '../../../../images/futurs.png';
import imgGood from '../../../../images/pro.png';
import imgFuturOwnerP from '../../../../images/futurs.webp';
import imgGoodP from '../../../../images/pro.webp';

import leftArrowImg from '../../../../images/arrow-gold-left.png';
import rightArrowImg from '../../../../images/arrow-gold-right.png';
import leftArrowImgP from '../../../../images/arrow-gold-left.webp';
import rightArrowImgP from '../../../../images/arrow-gold-right.webp';

const LeftArrow = () => (<Image alt="fleche gauche" className="arrow left mt-4" src={leftArrowImg} webp={leftArrowImgP} />);
const RightArrow = () => (<Image alt="fleche droite" className="arrow right mt-4" src={rightArrowImg} webp={rightArrowImgP} />);

const ScreenSectionForYouLinks = ({ futur, pro }) => {
	const intl = useIntl();
	const isEn = intl.locale === 'en' ? '/en' : '';
	return (
		<ScreenSection className="ScreenSectionForYouLinks">
			<Row>

				{
					futur === true
						? (
							<Col className="col-12 col-lg-6 my-5">
								<div className="d-flex flex-column flex-lg-row justify-content-between align-items-center">
									<Image alt="futurs proprietaires" className="d-block img" src={imgFuturOwner} webp={imgFuturOwnerP} />
									<NavLink
										className="d-flex flex-column justify-content-between"
										to={`${isEn}/futurs-proprietaires`}
									>
										<span className="text-secondary text-right text-uppercase">
											<FM
												id="forYouLinks.prop"
												defaultMessage="pour vous {br}<strong>futurs {br}propriétaires</strong>"
												values={{
													br: <br />,
													strong: (...chunks) => <strong>{chunks}</strong>,
												}}
											/>
										</span>
										<LeftArrow />
									</NavLink>
								</div>
							</Col>
						) : (
							<Col className="col-12 col-lg-6 my-5">
								<div className="d-flex flex-column flex-lg-row justify-content-between align-items-center">
									<Image alt="futurs proprietaires" className="d-block img" src={imgFuturOwner} webp={imgFuturOwnerP} />
									<NavLink
										className="d-flex flex-column justify-content-between"
										to={`${isEn}/biens-d-exception`}
									>
										<span className="text-secondary text-right text-uppercase">
											<FM
												id="forYouLinks.biens"
												defaultMessage="biens {br}<strong>d&apos;exception</strong>{br}&nbsp;"
												values={{
													br: <br />,
													strong: (...chunks) => <strong>{chunks}</strong>,
												}}
											/>
										</span>
										<LeftArrow />
									</NavLink>
								</div>
							</Col>
						)
				}
				{
					pro === true
						? (
							<Col className="col-12 col-lg-6 my-5">
								<div className="d-flex flex-column-reverse flex-lg-row justify-content-between align-items-center">
									<NavLink
										className="d-flex d-flex flex-column justify-content-between"
										to={`${isEn}/professionnels-de-l-immobilier`}
									>

										<span className="text-secondary text-left text-uppercase">
											<FM
												id="forYouLinks.pro"
												defaultMessage="pour vous {br}<strong>professionnels{br} de l'immobilier</strong>"
												values={{
													br: <br />,
													strong: (...chunks) => <strong>{chunks}</strong>,
												}}
											/>
										</span>
										<RightArrow />
									</NavLink>
									<Image alt="pro de limmobilier" className="d-block img" src={imgGood} webp={imgGoodP} />
								</div>
							</Col>
						) : (
							<Col className="col-12 col-lg-6 my-5">
								<div className="d-flex flex-column-reverse flex-lg-row justify-content-between align-items-center">
									<NavLink
										className="d-flex d-flex flex-column justify-content-between"
										to={`${isEn}/biens-d-exception`}
									>
										<span className="text-secondary text-left text-uppercase">
											<FM
												id="forYouLinks.biens"
												defaultMessage="biens {br}<strong>d&apos;exception</strong>{br}&nbsp;"
												values={{
													br: <br />,
													strong: (...chunks) => <strong>{chunks}</strong>,
												}}
											/>
										</span>
										<RightArrow />
									</NavLink>
									<Image alt="pro de limmobilier" className="d-block img" src={imgGood} webp={imgGoodP} />
								</div>
							</Col>
						)

				}
			</Row>
		</ScreenSection>
	);
};


ScreenSectionForYouLinks.propTypes = {
	futur: PropTypes.bool,
	pro: PropTypes.bool,
};

ScreenSectionForYouLinks.defaultProps = {
	futur: true,
	pro: true,
};

export default React.memo(ScreenSectionForYouLinks);
