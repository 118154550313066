import PropTypes from 'prop-types';
import React from 'react';
import { withScriptjs, withGoogleMap, GoogleMap } from 'react-google-maps';

import Marker from './Marker/Marker';

import customStyle from '../../../../styles/mapstyle.json';

const ScreenSectionMap = withScriptjs(withGoogleMap(({ lat, lng }) => (
	<GoogleMap
		defaultZoom={14}
		center={{ lat, lng }}
		defaultOptions={{ styles: customStyle }}
	>
		<Marker
			location={{ lat, lng }}
		/>
	</GoogleMap>
)));

ScreenSectionMap.propTypes = {
	lat: PropTypes.number.isRequired,
	lng: PropTypes.number.isRequired,
};

export default ScreenSectionMap;
