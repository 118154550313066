import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { footNoteLegalObj, pageFootNoteLegalObj, pageFootNoteLegalObjEN } from '../data';
import './AsteriskFooter.scss';

const AsteriskFooter = ({ page }) => {
	const [arrayOfIdTip, setArrayOfIdTip] = useState(pageFootNoteLegalObj[page]);
	const intl = useIntl();

	useEffect(() => {
		const isEn = intl.locale === 'en';
		if (isEn) {
			setArrayOfIdTip(pageFootNoteLegalObjEN[page]);
		} else {
			setArrayOfIdTip(pageFootNoteLegalObj[page]);
		}
	}, [intl.locale, page]);

	arrayOfIdTip.map((id) => {
		footNoteLegalObj[id].ref = React.createRef();
		return null;
	});
	return (
		<ul className="AsteriskFooter container list-unstyled mb-0">
			{(arrayOfIdTip || []).map((idTip, index) => (
				<li
					key={idTip}
					ref={footNoteLegalObj[idTip].ref}
				>
					{index + 1}
					{' '}
					: {footNoteLegalObj[idTip].label}
				</li>
			))}
		</ul>
	);
};

AsteriskFooter.propTypes = {
	page: PropTypes.string.isRequired,
};

export default AsteriskFooter;
