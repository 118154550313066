import React from 'react';
import PropTypes from 'prop-types';

import { Col } from 'reactstrap';
import './Right.scss';

const ScreenColRight = ({ children }) => (
	<Col className="ScreenColRight" xs="12" lg="5">
		{children}
	</Col>
);

ScreenColRight.propTypes = {
	children: PropTypes.node.isRequired,
};

export default React.memo(ScreenColRight);
