import React from 'react';
import PropTypes from 'prop-types';


const Image = ({ src, webp, ...props }) => (
	<picture>
		{ webp && <source srcSet={webp} type="image/webp" /> }
		<img src={src} {...props} />
	</picture>
);

Image.propTypes = {
	src: PropTypes.string.isRequired,
	webp: PropTypes.string,
};

Image.defaultProps = {
	webp: null,
};

export default React.memo(Image);
