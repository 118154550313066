import { FormattedMessage as FM, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import React, { useEffect } from 'react';
import {
	Button, Container, Col, Row,
} from 'reactstrap';
import { Link } from 'react-router-dom';

import Image from '@cecaz-immo/ui/dist/components/image/image';

import Screen from '../components/Screen/Screen';
import ScreenHeader from '../components/Screen/Header/ScreenHeader';
import ScreenHeaderImage from '../components/Screen/Header/Image/Image';

import ScreenSectionPartnersPrensentation from '../components/Screen/Section/PartnersPresentation/PartnersPresentation';
// import ScreenSectionSearchContainer from '../components/Screen/Section/Search/Search-container';
import ScreenSectionContact from '../components/Screen/Section/Contact/Contact';
import ScreenSectionForYouLinks from '../components/Screen/Section/ForYouLinks/ForYouLinks';
import ScreenSectionFutursAndProLinks from '../components/Screen/Section/FutursAndProLinks/FutursAndProLinks';
import IndexToolTip from '../components/LegalNote/IndexToolTip/IndexToolTip';
import AsteriskFooter from '../components/LegalNote/AsteriskFooter/AsteriskFooter';

import ScreenSectionPropertyLatest from '../components/Screen/Section/PropertyLatest/PropertyLatest';

import './Home.scss';

import imgIndex from '../images/LPby.png';
import imgIndexP from '../images/LPby.webp';
import imgSepGold from '../images/sep-gold.png';
import imgSepGoldP from '../images/sep-gold.webp';

// Actions
import { getRandomProgrammes as getRandomProgrammesActions } from '../store/actions/programme';

const showConsultezLesBiensDexception = false;

const Home = () => {
	const intl = useIntl();
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getRandomProgrammesActions());
	}, [dispatch]);

	const randomProgrammes = useSelector((state) => state.programme.randomProgrammes);

	return (
		<Screen className="PageHome">
			<Helmet>
				<title>Luxury properties</title>
			</Helmet>
			<ScreenHeader className="position-relative home">
				{ /* background image */}
				<ScreenHeaderImage className="bgHome" />

				<Container fluid>
					<Row className="no-gutters">
						<Col
							xl={{ size: 6, offset: 3 }}
							lg={{ size: 8, offset: 2 }}
							md={{ size: 10, offset: 1 }}
							sm={{ size: 12, offset: 0 }}
						>
							{ /* blue background */}
							<div className="imgHome">
								<figure className="py-4 m-0">
									{ /* Luxury properties logo */}
									<Image
										alt="luxury properties"
										className="imgIndex mw-100"
										src={imgIndex}
										webp={imgIndexP}
										width="500px"
										height="67px"
									/>
									<figcaption className="figCaption my-4">
										<FM
											id="home.punchline"
											defaultMessage="Financez{tip} votre {br1} acquisition {br2} dans l'immobilier {br1} de luxe"
											values={{
												br1: <br className="d-block d-md-none" />,
												br2: <br />,
												tip: <IndexToolTip num="1" id="financez" />,
											}}
										/>
									</figcaption>

									{ /* button for mobile */}
									{
										showConsultezLesBiensDexception
										&& (
											<div className="cta-property text-center d-md-none my-4">
												<Button className="btn-light text-primary rounded-0 text-uppercase" href="#properties">
													Consultez les biens d&apos;exception
												</Button>
											</div>
										)
									}
									{ /* bottom punchline*/}
									<p className="punchline text-secondary text-uppercase">
										Immobilier d&#39;exception par <br className="d-block d-md-none" /><strong>La caisse d&#39;epargne</strong>
									</p>
								</figure>
							</div>
							{ /* button for desktop */}
							{
								showConsultezLesBiensDexception
									? (
										<div className="cta-property text-center d-none d-md-block my-4">
											<Button className="btn-light text-primary rounded-0 text-uppercase" href="#properties">
												Consultez les biens d&apos;exception
											</Button>
										</div>
									) : <span className="d-block py-4 my-4" />
							}
						</Col>
					</Row>
				</Container>
			</ScreenHeader>
			<ScreenSectionFutursAndProLinks />
			<Col className="properties-contents mt-5">
				<p>
					<FM
						id="home.ctx"
						defaultMessage="Dans un contexte de taux bas, alléger votre fiscalité{tip} en ayant recours au financement bancaire."
						values={{
							tip: <IndexToolTip num="3" id="fiscalité" />,
						}}
					/>
				</p>
				<Link to={`${intl.locale === 'en' ? '/en/' : '/'}contact`}>
					<FM
						id="home.cta"
						defaultMessage="Nos experts Luxury Properties sont à votre disposition et à votre écoute."
					/>
				</Link>
				<br />
			</Col>
			{
				process.env.REACT_APP_SHOW_PROPERTIES === 'true'
					? (
						<div id="properties" className="properties-container mt-5">
							<Container fluid>
								<Row>
									<Col className="py-4">
										{/* <h2 className="text-uppercase">Consultez nos biens d&#39;exception</h2>
										// <ScreenSectionSearchContainer history={history} />
										<div className="dotline my-5" /> */}
										<h2 className="pb-4">NOUVEAUTÉS</h2>
										<ScreenSectionPropertyLatest
											className="carousel-home"
											properties={randomProgrammes}
										/>

									</Col>
								</Row>
							</Container>
						</div>
					) : (<span className="d-block my-4 py-4" />)
			}
			<div className="signatureContainer">
				<Container>
					<Row className="flex-sm-column flex-md-row">
						<Col className="flex-shrink-1 text-right">
							<div className="signature py-5">
								<FM
									id="home.signature"
									tagName="h2"
									defaultMessage="Notre signature : {br} l'immobilier de {br} <strong>PRESTIGE</strong>"
									values={{
										br: <br />,
										strong: (...chunks) => <strong>{chunks}</strong>,
									}}
								/>
							</div>
							<div className="accompagnement">
								<h3 className="text-uppercase">
									<FM
										id="home.accompagnies"
										defaultMessage="ACCOMPAGNEZ VOS PROJETS D&apos;IMMOBILIER HAUT DE GAMME"
									/>
								</h3>
							</div>
						</Col>
						<div className="sep-gold p-5 d-none d-md-block">
							<Image alt="sep-gold" src={imgSepGold} webp={imgSepGoldP} />
						</div>
						<Col className="flex-shrink-1">
							<div className="gold pt-5 listItem">
								<p>
									<FM
										id="home.prestige.p1"
										defaultMessage="Besoin d'un accompagnement personnalisé pour votre prochaine propriété de luxe ? Professionnels de l’immobilier et futurs propriétaires, avec le Centre d’Affaires Luxury Properties, la Caisse d’Epargne Côte d’Azur vous guide pour le financement de demeures de prestige. Avec des services sur-mesure, des solutions de financement pour l’achat de vos biens immobiliers (maison de luxe, appartement de luxe) et une écoute adaptée à vos besoins, la Caisse d’Epargne Côte d’Azur vous offre l’expertise et l’excellence d’un grand groupe national"
									/>
								</p>
							</div>
							<div className="blue pb-5 listItem">
								<p>

									<FM
										id="home.prestige.p2"
										defaultMessage="Forte de l’appui financier du Groupe BPCE, deuxième groupe bancaire en France, la Caisse d’Epargne Côte d’Azur accompagne les propriétaires, les professionnels immobiliers et l’ensemble des décideurs de l’économie régionale : entreprises et institutionnels."
									/>
									<br />
								</p>
								<p>

									<FM
										id="home.prestige.p3"
										defaultMessage="Le Centre d’Affaires Luxury Properties vous accompagne dans le financement{tip} de vos résidences de luxe sur la Côte d’Azur, mais aussi à Paris et dans les Alpes. Profitez d’un cadre de vie privilégié grâce à un partenaire bancaire présent tout au long de vos projets immobiliers de prestige."
										values={{
											tip: <IndexToolTip num="4" id="financement2" />,
										}}
									/>
								</p>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
			<ScreenSectionPartnersPrensentation />
			<ScreenSectionContact />
			<ScreenSectionForYouLinks />
			<AsteriskFooter page="home" />
		</Screen>
	);
};

Home.propTypes = {
	history: PropTypes.shape({ push: PropTypes.func }),
};

Home.defaultProps = {
	history: {},
	// randomProgrammes: [],
};

export default Home;
