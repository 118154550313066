import PropTypes from 'prop-types';
import React from 'react';

import './ScreenHeader.scss';

const ScreenHeader = ({ children, className }) => (
	<header className={`ScreenHeader ${className} mb-5`}>
		{children}
	</header>
);

ScreenHeader.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
};

ScreenHeader.defaultProps = {
	className: '',
};

export default React.memo(ScreenHeader);
