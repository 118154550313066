import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
// import {
// 	NavLink,
// } from 'react-router-dom';
import {
	Col, Row,
} from 'reactstrap';

import Image from '@cecaz-immo/ui/dist/components/image/image';

import Carousel from '../../Carousel/carousel';
import ScreenSection from '../Section/ScreenSection';

import partnersData from './partners.data';
// import searchIcon from '../../../images/search.png';
// import searchIconP from '../../../images/search.webp';

const Partners = ({ selectedPartner }) => {
	const partner = useMemo(() => partnersData[selectedPartner], [selectedPartner]);
	return (
		partner
		&& (
			<>
				<Carousel
					className="carousel-partners"
					items={partner.properties}
				/>
				<ScreenSection>
					<Row className="partner">
						<Col md="3" className="bg-info p-0">
							<Image alt="gérard" className="artmaker" src={partner.photo} webp={partner.photoWebp} />
							<div className="w-75 gold mx-auto p-2 py-5">
								<p>
									{partner.descriptionShort(2)}
								</p>
							</div>
						</Col>
						<Col md="9" className="pt-5 bg-light">
							<Row>
								<Col md="6" className="gold pl-5">
									<h3 className="m-0 text-uppercase">{partner.name}</h3>
									<span>{partner.job(1)}</span>
									<hr />
								</Col>
								{/* { process.env.REACT_APP_SHOW_PROPERTIES === 'true'
									&& (
										<Col md="6" className="bg-primary d-flex justify-content-end pr-0">
											<div className="white searchTitle pb-2 pl-2 pt-2 pr-3 text-right">
												CONSULTER SES CRÉATIONS <br />
												DISPONIBLES À LA VENTE
											</div>
											<div className="px-5 m-auto">
												<NavLink to={`/rechercher#partner=${partner.id}`}>
													<Image alt="search" className="searchIcon" src={searchIcon} webp={searchIconP} />
												</NavLink>
											</div>
										</Col>
									)} */}
							</Row>
							<Row>
								<Col>
									<div className="biographie gold w-90 pt-5">
										{partner.descriptionLong}
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
				</ScreenSection>
			</>
		)
	);
};

Partners.propTypes = {
	selectedPartner: PropTypes.string.isRequired,
};

export default React.memo(Partners);
