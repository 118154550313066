const { REACT_APP_API_URL } = process.env;


export const GET_LOCATIONS = 'GET_LOCATIONS';

const getLocationsAction = locations => ({
	type: GET_LOCATIONS,
	locations,
});

export const getLocations = () => dispatch => fetch(
	`${REACT_APP_API_URL}/lp/locations`,
	{
		headers: {
			'Cache-Control': 'no-cache',
		},
	},
)
	.then(resp => resp.json())
	.then((result) => {
		dispatch(getLocationsAction(result));
	});
