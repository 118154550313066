import '@cecaz-immo/ui/dist/lib/polyfill';

// react-intl support polyfill
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/fr'; // Add locale data for de
import '@formatjs/intl-relativetimeformat/dist/locale-data/en'; // Add locale data for de
import smoothscroll from 'smoothscroll-polyfill';
import React from 'react';
import { hydrate, render } from 'react-dom';
import '@cecaz-immo/ui/dist/lib/rollbar';

import 'react-jsonschema-form-validation/dist/react-jsonschema-form-validation.css';
import './index.scss';

import App from './App';
import * as serviceWorker from './serviceWorker';

const rootElement = document.getElementById('root');

smoothscroll.polyfill();

if (rootElement.hasChildNodes()) {
	hydrate(<App />, rootElement);
} else {
	render(<App />, rootElement);
}

serviceWorker.unregister();
// eslint-disable-next-line no-console
console.info(`ℹ️ v${process.env.REACT_APP_VERSION} show properties: ${process.env.REACT_APP_SHOW_PROPERTIES === 'true'}`);
