import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Container } from 'reactstrap';
import './ScreenSection.scss';

const ScreenSection = ({ children, className }) => (
	<Container tag="section" className={classnames('ScreenSection', className)}>
		{ children }
	</Container>
);

ScreenSection.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
};

ScreenSection.defaultProps = {
	className: '',
};

export default React.memo(ScreenSection);
