import Helmet from 'react-helmet';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';

import LegalCGU from '../../components/Legal/Cgu';
import Screen from '../../components/Screen/Screen';

const {
	REACT_APP_EMAIL_LEGAL,
	REACT_APP_EMAIL_PUBLIC,
	REACT_APP_WWW,
} = process.env;

export default () => (
	<Screen className="MentionsLegales">
		<Helmet>
			<title>Luxury properties – Conditions générales d’utilisation</title>
		</Helmet>
		<Container className="mb-5 pb-5">
			<Row>
				<Col>
					<LegalCGU
						appEmailLegal={REACT_APP_EMAIL_LEGAL}
						appEmailPublic={REACT_APP_EMAIL_PUBLIC}
						appWWW={REACT_APP_WWW}
					/>
				</Col>
			</Row>
		</Container>

	</Screen>
);
