import PropTypes from 'prop-types';
import React from 'react';
import { Marker } from 'react-google-maps';

import MarkerIcon from '../../../../../images/marker.svg';

const MapMarker = ({ location }) => (
	<Marker
		position={location}
		icon={MarkerIcon}
	/>
);

MapMarker.propTypes = {
	location: PropTypes.objectOf(PropTypes.number).isRequired,
};

export default React.memo(MapMarker);
