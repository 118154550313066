import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import './Title.scss';

const ScreenHeaderTitle = ({ theme, colLeft, colRight }) => (
	<Container className={classnames('ScreenHeaderTitle', `theme-${theme}`)}>
		<Row className="ScreenHeaderTitle-row">
			<Col tag="h1" className="ScreenHeaderTitle-col-left py-5">
				<div className="hyphen" />
				<div className="clearfix" />
				{colLeft}
			</Col>
			<div className={`sep-${theme}`}>
				<img alt={`img-${theme}`} className={`img-${theme}`} />
			</div>
			<Col className="ScreenHeaderTitle-col-right py-5">
				{colRight}
			</Col>
		</Row>
	</Container>
);

ScreenHeaderTitle.propTypes = {
	theme: PropTypes.string,
	colLeft: PropTypes.node.isRequired,
	colRight: PropTypes.node.isRequired,
};

ScreenHeaderTitle.defaultProps = {
	theme: 'blue',
};

export default React.memo(ScreenHeaderTitle);
