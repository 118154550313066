import React from 'react';
import Map from '../Map';


const ScreenSectionMapContainer = ({ lat, lng }) => (
	<Map
		googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDuVeuzoWqoMd_799OpRXaFgjDvpTBmyGQ&v=3.exp&libraries=geometry,drawing,places"
		loadingElement={<div style={{ height: '100%' }} />}
		containerElement={<div style={{ height: '600px', width: '100%' }} />}
		mapElement={<div style={{ height: '100%' }} />}
		lat={lat}
		lng={lng}
	/>
);


ScreenSectionMapContainer.propTypes = {
	...Map.PropTypes,
};

export default React.memo(ScreenSectionMapContainer);
