import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { getLocations as getLocationsAction } from '../../../../store/actions/location';

import Search from './Search';

class SearchContainer extends PureComponent {
	constructor(props) {
		super(props);
		const {
			getLocations,
		} = this.props;

		getLocations();
		this.onSearchProgramme = this.onSearchProgramme.bind(this);
	}

	onSearchProgramme(search) {
		const { history } = this.props;
		//const params = SearchParams.searchDataToParams(search);
		history.push(`/rechercher#locationZone=${search.locationZone}`);
	}

	render() {
		const {
			programmeLocations,
		} = this.props;

		const lp = Array.isArray(programmeLocations) ? programmeLocations : [];

		return (
			<Search
				programmeLocations={lp}
				onSearch={this.onSearchProgramme}
			/>
		);
	}
}

SearchContainer.propTypes = {
	history: PropTypes.shape({ push: PropTypes.func }),
	getLocations: PropTypes.func.isRequired,
	programmeLocations: PropTypes.arrayOf(PropTypes.shape({})),
};

SearchContainer.defaultProps = {
	history: {},
	programmeLocations: [],
};

const mapStateToProps = state => ({
	programmeLocations: state.locations,
});

const mapDispatchToProps = dispatch => ({
	getLocations: () => dispatch(getLocationsAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchContainer);
