import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import {
	Button, Col, Row,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCircleNotch } from '@fortawesome/free-solid-svg-icons';

import * as SearchParams from '@cecaz-immo/app-client/dist/lib/search-params';

import PropertyList from '../components/Property/List';
import Screen from '../components/Screen/Screen';
import ScreenSection from '../components/Screen/Section/ScreenSection';
import ScreenSectionSearchContainer from '../components/Screen/Section/Search/Search-container';

// Actions
import {
	searchProgramme,
} from '../store/actions/programme';

import './Property-results.scss';

class PropertySearch extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
		};

		//Search programmes
		//const { getLastProgrammes } = this.props;
		//getLastProgrammes().then(() => this.setState({ isLoaded: true }));

		this.onClickClosePartner = this.onClickClosePartner.bind(this);
	}

	componentWillMount() {
		const { search, dispatchSearchProgramme } = this.props;
		//if (search && search.locationZone) {
		if (search) {
			dispatchSearchProgramme(search).then(() => {
				this.setState({ loading: false });
			});
		}
	}

	componentWillReceiveProps({ search, dispatchSearchProgramme }) {
		if (search
			&& (
				// eslint-disable-next-line react/destructuring-assignment
				search.locationZone !== this.props.search.locationZone
				// eslint-disable-next-line react/destructuring-assignment
				|| search.partner !== this.props.search.partner
			)
		) {
			this.setState({ loading: true });
			dispatchSearchProgramme(search).then(() => {
				this.setState({ loading: false });
			});
		}
	}

	onClickClosePartner() {
		const { history, search } = this.props;
		//const params = SearchParams.searchDataToParams(search);
		history.push(`/rechercher#${search.locationZone ? `locationZone=${search.locationZone}` : ''}`);
	}

	render() {
		const { history, programmesSearched, search } = this.props;
		const { loading } = this.state;
		return (
			<Screen>
				<Helmet>
					<title>Luxury properties – Rechercher un bien</title>
				</Helmet>
				<div className="bg-primary">
					<ScreenSection className="filter py-5">
						<Row>
							<Col className="text-center" md="12">
								<h1>NOS BIENS D&#39;EXCEPTION</h1>
								<ScreenSectionSearchContainer history={history} />
							</Col>
						</Row>
					</ScreenSection>
				</div>
				{
					search.partner
					&&	(
						<ScreenSection>
							<Row className="no-gutters my-3">
								<Col md="5" className="bg-secondary partnerFilter">
									<Row>
										<Col md="8">
											<div className="ml-3 my-2">
												<strong>ARCHITECTE</strong><br />
												<h4>GERARD FAIVRE</h4>
											</div>
										</Col>
										<Col className="m-auto" md="4">
											<Button className="close mr-3" onClick={this.onClickClosePartner}>
												<FontAwesomeIcon icon={faTimes} />
											</Button>
										</Col>
									</Row>
								</Col>
							</Row>
						</ScreenSection>
					)
				}

				<ScreenSection className="results">
					{
						loading
							? (
								<div className="loading pt-4">
									<FontAwesomeIcon icon={faCircleNotch} className="fa-spin" />
								</div>
							)
							: <PropertyList properties={programmesSearched} />
					}
				</ScreenSection>
			</Screen>
		);
	}
}

PropertySearch.propTypes = {
	dispatchSearchProgramme: PropTypes.func.isRequired,
	history: PropTypes.shape({ push: PropTypes.func }),
	location: PropTypes.shape({}).isRequired,
	programmesSearched: PropTypes.arrayOf(PropTypes.shape({})),
	search: PropTypes.shape({
		locationZone: PropTypes.string,
		partner: PropTypes.string,
	}).isRequired,
};

PropertySearch.defaultProps = {
	history: {},
	programmesSearched: [],
};

const mapStateToProps = (state) => ({
	programmesSearched: state.programme.programmesSearched,
	searching: state.programme.searching,
});

const mapDispatchToProps = (dispatch) => ({
	dispatchSearchProgramme: (data) => dispatch(searchProgramme(data)),
});

const PropertySearchContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
)(PropertySearch);

export default (props) => {
	// eslint-disable-next-line react/prop-types
	const { location } = props;
	const searchData = SearchParams.paramsToSearchData(location);
	return <PropertySearchContainer {...props} search={searchData} />;
};
