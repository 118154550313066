import { FormattedMessage as FM, useIntl } from 'react-intl';
import React, { useState, useCallback } from 'react';
import { NavLink as RRNavLink, useRouteMatch } from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import {
	Button,
	Container,
	Collapse,
	Nav,
	Navbar,
	NavbarBrand,
	NavItem,
	NavLink,
	NavbarToggler,
} from 'reactstrap';

import Image from '@cecaz-immo/ui/dist/components/image/image';

import HeaderLang from '../Header/Lang/Lang';

import HeaderLogoGold from '../../images/logo.png';
import HeaderLogoWhite from '../../images/logo-white.png';
import HeaderLogoGoldP from '../../images/logo.webp';
import HeaderLogoWhiteP from '../../images/logo-white.webp';

import './NavBar.scss';

const NavBar = () => {
	const { trackEvent } = useMatomo();
	const [isOpen, setIsOpen] = useState(false);
	const match = useRouteMatch();
	const intl = useIntl();
	const isEn = intl.locale === 'en';
	const langPath = isEn ? '/en' : '';

	const toggleMenu = useCallback(() => {
		setIsOpen((prevState) => !prevState.isOpen);
	}, [setIsOpen]);

	const closeMenu = useCallback(() => {
		setIsOpen(false);
	}, [setIsOpen]);

	const clickContactButton = useCallback(() => {
		trackEvent({
			category: 'Contact',
			action: 'Click',
			value: 'HEADER_CLICK_CONTACT',
		});
		closeMenu();
	}, [closeMenu, trackEvent]);

	return (
		<Navbar className="NavBar fixed-top" color="faded" light expand="lg">
			<Container className="container p-0">
				<NavbarBrand className="mr-md-auto" tag={RRNavLink} to={`${langPath}/`} onClick={closeMenu}>
					<Image alt="LP" className="Navbar-logo desktop d-none d-md-block" src={HeaderLogoGold} webp={HeaderLogoGoldP} />
					<Image alt="LP" className="Navbar-logo d-block d-md-none" src={HeaderLogoWhite} webp={HeaderLogoWhiteP} />
				</NavbarBrand>
				<NavbarToggler onClick={toggleMenu} className="white border-0" />
				<Collapse isOpen={isOpen} navbar className="justify-content-center">
					<div className="p-4">
						<Nav navbar className="mx-auto d-flex align-items-center text-center position-relative">
							<NavItem>
								<NavLink tag={RRNavLink} to={`${langPath}/professionnels-de-l-immobilier`} onClick={closeMenu}>
									<FM
										id="navbar.pro"
										defaultMessage="Professionnels de l’immobilier"
									/>
								</NavLink>
							</NavItem>
							<span className="divider">|</span>
							<NavItem>
								<NavLink tag={RRNavLink} to={`${langPath}/futurs-proprietaires`} onClick={closeMenu}>
									<FM
										id="navbar.futur"
										defaultMessage="Futurs proprietaires"
									/>
								</NavLink>
							</NavItem>
							<span className="divider">|</span>
							{/* {
								process.env.REACT_APP_SHOW_PROPERTIES === 'true'
								&& (
									<>
										<NavItem>
											<NavLink tag={RRNavLink} to={`${langPath}/rechercher`} onClick={closeMenu}>
												rechercher un bien
											</NavLink>
										</NavItem>
										<span className="divider">|</span>
									</>
								)
							} */}
							<NavItem>
								<NavLink tag={RRNavLink} to="/bien/SERENA%20VISTA/p/9ee2a9e0-8479-417d-831e-562018a716b0" onClick={closeMenu}>
									Biens d&apos;exception
								</NavLink>
							</NavItem>
							<span className="divider">|</span>
							<NavItem>
								<NavLink tag={RRNavLink} to={`${langPath}/nos-partenaires/stephanie`} onClick={closeMenu}>
									<FM
										id="navbar.partners"
										defaultMessage="nos partenaires"
									/>
								</NavLink>
							</NavItem>
							<span className="divider">|</span>
							<NavItem>
								<NavLink
									tag={RRNavLink}
									to={match.params.uuid ? `${langPath}/contact/${match.params.uuid}` : `${langPath}/contact/`}
									onClick={clickContactButton}
									className="border-0"
								>
									<FM
										id="navbar.contact"
										defaultMessage="contact"
									/>
								</NavLink>
							</NavItem>
							<NavItem className="close-item d-flex justify-content-end align-items-center w-100 d-block d-lg-none">
								<HeaderLang className={`${isEn ? 'en' : 'fr'} beigeAndgold`} />
								<Button
									close
									onClick={closeMenu}
								/>
							</NavItem>
						</Nav>
					</div>
				</Collapse>
				<HeaderLang className={`${isEn ? 'en' : 'fr'} d-none d-lg-block`} />
			</Container>
		</Navbar>
	);
};

export default NavBar;
