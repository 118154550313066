import Helmet from 'react-helmet';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';

import LegalPolitiqueCookies from '../../components/Legal/Politique-cookies';
import Screen from '../../components/Screen/Screen';

import './Politique-cookies.scss';

export default () => (
	<Screen className="PolitiqueCookies">
		<Helmet>
			<title>Luxury properties – Politique de cookies</title>
		</Helmet>
		<Container className="mb-5 pb-5">
			<Row>
				<Col>
					<LegalPolitiqueCookies />
				</Col>
			</Row>
		</Container>

	</Screen>
);
