import { FormattedMessage as FM } from 'react-intl';
import React from 'react';

export const footNoteLegalObj = {
	financez: {
		label: <FM
			id="legalNote.financez"
			defaultMessage="Sous réserve d'acceptation de votre dossier par la Caisse d'Epargne Côte d'Azur. Voir modalités et conditions auprès de votre chargé d'affaires."
		/>,
	},
	fiscalité: {
		label: <FM
			id="legalNote.fiscalité"
			defaultMessage="Fiscalité avantageuse en matière d'imposition sur le revenu, selon les dispositions fiscales en vigueur applicable"
		/>,
	},
	financement: {
		label: <FM
			id="legalNote.financement"
			defaultMessage="Sous réserve d'acceptation de votre dossier par la Caisse d'Epargne Côte d'Azur. Voir modalités et conditions auprès de votre chargé d'affaires."
		/>,
	},
	financement2: {
		label: <FM
			id="legalNote.financement2"
			defaultMessage="Dans le cadre d'un crédit immobilier, sous réserve d'acceptation de votre dossier de crédit pour le financement d'une résidence principale, par la Caisse d'Epargne Côte d'Azur, prêteur, l'emprunteur dispose d'un délai de réflexion de dix jours avant d'accepter l'offre de crédit. La réalisation de la vente est subordonnée à l'obtention du prêt. Si celui-ci n'est pas obtenu, le vendeur doit rembourser les sommes versées."
		/>,
	},
	financements: {
		label: <FM
			id="legalNote.financements"
			defaultMessage="Sous réserve d'acceptation de votre dossier par la Caisse d'Epargne Côte d'Azur"
		/>,
	},
	'prise de participation': {
		label: <FM
			id="legalNote.priseDeParticipation"
			defaultMessage="Voir conditions et modalités auprès de votre chargé d'affaires"
		/>,
	},
	'crédits personnalisés': {
		label: <FM
			id="legalNote.creditsPersonnalisés"
			defaultMessage="Dans le cadre d'un crédit immobilier, sous réserve d'acceptation de votre dossier de crédit pour le financement d'une résidence principale, par la Caisse d'Epargne Côte d'Azur, prêteur, l'emprunteur dispose d'un délai de réflexion de dix jours avant d'accepter l'offre de crédit. La réalisation de la vente est subordonnée à l'obtention du prêt. Si celui-ci n'est pas obtenu, le vendeur doit rembourser les sommes versées."
		/>,
	},
	'placements sur-mesure': {
		label: <FM
			id="legalNote.placementsSurMesure"
			defaultMessage="Voir conditions et modalités auprès de votre conseiller"
		/>,
	},
};

export const pageFootNoteLegalObj = {
	// eslint-disable-next-line max-len
	home: ['financez', 'financement', 'fiscalité', 'financement2'],
	// eslint-disable-next-line max-len
	professionnelImmobilier: ['financements', 'prise de participation'],
	futursPropietaire: ['crédits personnalisés', 'placements sur-mesure'],
	partenaires: [],
};

export const pageFootNoteLegalObjEN = {
	// eslint-disable-next-line max-len
	home: ['financez', 'financement', 'fiscalité', 'financement2'],
	professionnelImmobilier: ['financements', 'prise de participation'],
	futursPropietaire: ['crédits personnalisés', 'placements sur-mesure'],
	partenaires: [],
};
