import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'reactstrap';

import './Subtitle.scss';

const ScreenSectionSubtitle = ({ className, children }) => (
	<Row className={`ScreenSectionSubtitle ${className}`}>
		<Col className="ScreenSectionSubtitle-title">
			{children}
		</Col>
	</Row>
);

ScreenSectionSubtitle.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
};


ScreenSectionSubtitle.defaultProps = {
	className: '',
};


export default React.memo(ScreenSectionSubtitle);
