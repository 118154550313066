import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import {
	Button, Container, Col, Table, Row,
} from 'reactstrap';
import { NavLink } from 'react-router-dom';

import Carousel from '../Carousel/carousel';
import ScreenSection from '../Screen/Section/ScreenSection';
import ScreenSectionImage from '../Screen/Section/Image/Image';
import ScreenSectionMapContainer from '../Screen/Section/Map/Container/MapContainer';

import './Description.scss';

const { REACT_APP_AWS_S3_URL } = process.env;

class PropertyDescription extends PureComponent {
	render() {
		const {
			displayMode,
			property,
		} = this.props;

		const {
			description,
			district,
			files,
			lat,
			lng,
			location,
			name,
			tags,
			uuid,
		} = property;

		const lots = property.lots && property.lots.sort((a, b) => (a.rooms - b.rooms));
		const programmePhotos = (files || []).map(f => ({
			src: REACT_APP_AWS_S3_URL + f.name,
		}));
		const mainPhoto = (programmePhotos.length && programmePhotos[0].src) || null;

		return (
			<section className="PropertyDescription">
				<ScreenSectionImage name="image" src={mainPhoto} />
				<Container>
					<Row>
						<Col
							md="4"
							lg="3"
							className="intro bg-secondary d-flex flex-column justify-content-between p-4 text-white"
						>
							<div className="mb-4 pb-4">
								<span className="font-weight-bold">{location && location.label}</span>
								<h1>{name}</h1>
							</div>
							<span className="asset pt-3">{district}</span>
						</Col>
						<Col md="8" lg="9" className="bg-info py-5">
							<p>
								{ description }
							</p>
						</Col>
					</Row>
				</Container>

				<ScreenSection>
					<Row>
						<Col className="text-center mt-5">
							<NavLink to={`/contact/${uuid}`}>
								<Button
									disabled={!displayMode}
									className="btn-cta gold"
									type="button"
								>
									OBTENIR DES INFORMATIONS SUR CE BIEN
								</Button>
							</NavLink>
						</Col>
					</Row>

					<div className="carouselContainer bg-white my-5 text-center">
						<Carousel className="carousel-property blue" items={programmePhotos} />
					</div>
				</ScreenSection>


				<div className="bg-info py-5 specs">
					<ScreenSection>
						<Row>
							<Col lg="3" className="col">
								<div className="title text-secondary pb-4 pb-lg-0">
									<span className="font-weight-bold">{ location && location.label }</span>
									<h2>{ name }</h2>
								</div>
							</Col>
							<Col lg="1" className="d-none d-lg-block">
								<hr />
							</Col>
							<Col lg="8" className="pt-4 pt-lg-0">
								<h2>CARACTÉRISTIQUES</h2>
								<ul className="list-unstyled">
									<Table borderless className="TablePropertySpecs">
										<tbody>
											<tr>
												<th scope="row">Pièces</th>
												{ lots && lots.map(lot => (
													<td key={`room${lot.id}`}>
														{lot.rooms}
													</td>
												)) }
											</tr>
											<tr>
												<th scope="row">Surface</th>
												{ lots && lots.map(lot => (
													<td key={`area${lot.id}`}>
														{lot.minArea}m²
													</td>
												)) }
											</tr>
										</tbody>
									</Table>
									{ tags
										&& (
											<li className="pt-4">
												<span>Les plus du bien</span>
												<ul className="list-unstyled d-flex specs-plus">
													{
														tags.map(t => (
															<li key={`tag${t.id}`}>{t.label}</li>
														))
													}
												</ul>
											</li>
										)
									}
								</ul>
							</Col>
						</Row>
					</ScreenSection>
				</div>

				<div className="bg-primary">
					<ScreenSection>
						<Row>
							<Col className="text-center my-5">
								<NavLink to={`/contact/${uuid}`}>
									<Button
										className="btn-cta white"
										disabled={!displayMode}
										type="button"
									>
										OBTENIR DES INFORMATIONS SUR CE BIEN
									</Button>
								</NavLink>
							</Col>
						</Row>
					</ScreenSection>
				</div>

				{
					lat && lng
					&& <ScreenSectionMapContainer lat={lat} lng={lng} />
				}
			</section>
		);
	}
}

PropertyDescription.propTypes = {
	displayMode: PropTypes.bool,
	property: PropTypes.shape({
		description: PropTypes.string,
		district: PropTypes.string,
		files: PropTypes.array,
		lat: PropTypes.number,
		lots: PropTypes.array,
		lng: PropTypes.number,
		location:PropTypes.string,
		name: PropTypes.string,
		tags: PropTypes.array,
		uuid: PropTypes.string,
	}).isRequired,
};

PropertyDescription.defaultProps = {
	displayMode: true,
};

export default PropertyDescription;
