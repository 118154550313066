import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import {
	Button,
	Carousel as RSCarousel,
	CarouselCaption,
	CarouselItem,
	CarouselIndicators,
	CarouselControl,
} from 'reactstrap';

import Image from '@cecaz-immo/ui/dist/components/image/image';

import './carousel.scss';

class Carousel extends PureComponent {
	constructor(props) {
		super(props);
		this.state = { activeIndex: 0 };

		this.goToIndex = this.goToIndex.bind(this);
		this.next = this.next.bind(this);
		this.onExiting = this.onExiting.bind(this);
		this.onExited = this.onExited.bind(this);
		// this.onEntering = this.onEntering.bind(this);
		this.previous = this.previous.bind(this);
		this.getCarouselItems = this.getCarouselItems.bind(this);
	}

	// onEntering({ clientHeight }) {
	// 	this.setState({ carouselHeight: clientHeight });
	// }

	onExiting() {
		this.animating = true;
	}

	onExited() {
		this.animating = false;
	}

	getCarouselItems() {
		const { items } = this.props;
		return items.map((item) => (
			<CarouselItem
				key={item.src}
				onEntering={this.onEntering}
				onExiting={this.onExiting}
				onExited={this.onExited}
				//style={{ height: this.state.carouselHeight, background: 'red' }}
			>
				<Image src={item.src} webp={item.webp} alt={item.altText} className="carousel-img" />
				<>
					{
						item.caption
						&& <CarouselCaption captionText={item.caption} captionHeader={item.altText} />
					}
					{
						item.plus
						&& (
							<NavLink to={item.plus}>
								<Button className="goodButtons py-2">EN SAVOIR PLUS</Button>
							</NavLink>
						)
					}
				</>
			</CarouselItem>
		));
	}


	goToIndex(newIndex) {
		if (this.animating) return;
		this.setState({ activeIndex: newIndex });
	}

	previous() {
		const { activeIndex } = this.state;
		const { items } = this.props;

		if (this.animating) return;
		const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
		this.setState(() => ({ activeIndex: nextIndex }));
	}

	next() {
		const { activeIndex } = this.state;
		const { items } = this.props;

		if (this.animating) return;
		const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
		this.setState(() => ({ activeIndex: nextIndex }));
	}

	render() {
		const { activeIndex } = this.state;
		const { className, items } = this.props;
		return (
			<RSCarousel
				activeIndex={activeIndex}
				className={classNames(className, 'Carousel')}
				next={this.next}
				previous={this.previous}
				//interval={1000000}
			>
				<CarouselIndicators
					activeIndex={activeIndex}
					items={items}
					onClickHandler={this.goToIndex}
				/>
				{this.getCarouselItems()}
				{
					items.length > 1 && (
						<>
							<CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
							<CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
						</>
					)
				}

			</RSCarousel>
		);
	}
}

Carousel.propTypes = {
	className: PropTypes.string,
	items: PropTypes.arrayOf(PropTypes.shape({
		src: PropTypes.string.isRequired,
		alt: PropTypes.string,
		caption: PropTypes.string,
		plus: PropTypes.string,
	}).isRequired).isRequired,
};

Carousel.defaultProps = {
	className: '',
};

export default Carousel;
