import PropTypes from 'prop-types';
import React from 'react';
import './Header.scss';

const Header = ({ children }) => (
	<header className="Header">
		{children}
	</header>
);

Header.propTypes = {
	children: PropTypes.node.isRequired,
};

export default React.memo(Header);
