import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
// import {
// 	Col, Row,
// } from 'reactstrap';

import PropertyDescription from '../components/Property/Description';
// import PropertyList from '../components/Property/List';
import Screen from '../components/Screen/Screen';
// import ScreenSection from '../components/Screen/Section/ScreenSection';
import ScreenSectionContact from '../components/Screen/Section/Contact/Contact';
import ScreenSectionForYouLinks from '../components/Screen/Section/ForYouLinks/ForYouLinks';


// Actions
import { getCurrentProgramme, getOtherProgrammes } from '../store/actions/programme';

import './Property.scss';

class Property extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			pending: true,
		};
		const { loadProgrammes } = this.props;
		loadProgrammes().finally(() => {
			this.setState({
				pending: false,
			});
		});
	}

	render() {
		const { pending } = this.state;
		const { programme/*, otherProgrammes*/ } = this.props;
		if (pending === false && Object.keys(programme).length === 0) {
			return <Redirect to="/404" />;
		}
		return (
			<Screen className="Property">
				<Helmet>
					<title>Luxury properties - Property</title>
				</Helmet>

				<PropertyDescription property={programme} />

				{/* <ScreenSection>
					<Row className="my-5">
						<Col className="text-center gold">
							<h3>NOS BIENS D&#39;EXCEPTION SIMILAIRES</h3>
						</Col>
					</Row>
					<PropertyList properties={otherProgrammes} />
				</ScreenSection> */}

				<ScreenSectionContact />
				<ScreenSectionForYouLinks />
			</Screen>
		);
	}
}


Property.propTypes = {
	history: PropTypes.shape({ push: PropTypes.func }).isRequired,
	match: PropTypes.shape({ params: PropTypes.shape({ uuid: PropTypes.string }) }).isRequired,
	loadProgrammes: PropTypes.func.isRequired,
	programme: PropTypes.shape({ commentary: PropTypes.string }).isRequired,
	//otherProgrammes: PropTypes.arrayOf(PropTypes.object),
};

Property.defaultProps = {
	//otherProgrammes: [],
};

const mapStateToProps = (state) => ({
	programme: state.programme.currentProgramme,
	otherProgrammes: state.programme.otherProgrammes,
});

const mapDispatchToProps = (dispatch, ownProps) => {
	let { uuid } = ownProps.match.params;
	return {
		loadProgrammes: (newUuid) => {
			// cannot use the uuid on upper scope when loadProgrammes is called
			// by the componentWillReceiveProps
			uuid = newUuid || uuid;
			if (!uuid) {
				throw new Error('Invalid uuid.');
			}
			return Promise.all([
				dispatch(getCurrentProgramme(uuid)),
				dispatch(getOtherProgrammes(uuid)),
			]);
		},
	};
};


export default connect(mapStateToProps, mapDispatchToProps)(Property);
