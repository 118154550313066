import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Button, Label } from 'reactstrap';

import './Search.scss';

class ScreenSectionSearch extends PureComponent {
	constructor(props) {
		super(props);
		// console.log('constructor Bandeau');
		this.state = { locationZone: '' };

		//radio button search
		this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
		this.onClickSearch = this.onClickSearch.bind(this);
	}

	onClickSearch() {
		const { onSearch } = this.props;
		const { locationZone } = this.state;
		onSearch({ locationZone });
	}

	onRadioBtnClick(location) {
		this.setState({ locationZone: location.diacritic });
	}

	render() {
		const { programmeLocations } = this.props;
		const { locationZone } = this.state;
		return (
			<>
				<ul className="list-unstyled mt-5 col-12 d-flex flex-column flex-lg-row justify-content-center">
					{ programmeLocations && programmeLocations.map(l => (
						<li key={`k${l.id}`} className="px-4">
							<Label>
								<input
									className="with-gap mx-2"
									type="radio"
									checked={locationZone === l.diacritic}
									onChange={() => this.onRadioBtnClick(l)}
								/>
								<span>{l.label}</span>
							</Label>
						</li>
					)) }
				</ul>
				<Button className="goodButtons my-4" onClick={this.onClickSearch}>RECHERCHER</Button>
			</>
		);
	}
}

ScreenSectionSearch.propTypes = {
	programmeLocations: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number.isRequired,
		diacritic: PropTypes.string.isRequired,
		label: PropTypes.string.isRequired,
	})).isRequired,
	onSearch: PropTypes.func.isRequired,
};

ScreenSectionSearch.defaultProps = {
};

export default ScreenSectionSearch;
