import React, { useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import {
	Col, Container, Row,
} from 'reactstrap';

import Image from '@cecaz-immo/ui/dist/components/image/image';

import './Footer.scss';

import logoCE from '../../images/logo-cecaz-gold.png';
//import logoCEP from '../../images/logo-cecaz-gold.png';

const Footer = () => {
	const { trackEvent } = useMatomo();

	const handleClickContact = useCallback(() => {
		trackEvent({
			category: 'Contact',
			action: 'Click',
			value: 'FOOTER_CLICK_CONTACT',
		});
	}, [trackEvent]);

	return (
		<footer className="Footer my-5">
			<Container>
				<Row>
					<Col className="col-12 col-lg-auto d-flex justify-content-center justify-content-lg-center align-items-center mt-5">
						<ul className="links list-unstyled d-flex flex-column flex-lg-row mb-0 flex-wrap">
							<li className="text-center mb-4 mb-lg-0">
								<NavLink className="text-secondary" to="/">
									&copy; LUXURY PROPERTIES 2022
								</NavLink>
								<span className="d-none d-lg-inline px-lg-2 text-secondary">|</span>
							</li>
							<li className="text-center mb-4 mb-lg-0">
								<NavLink className="text-secondary" to="/contact" onClick={handleClickContact}>
									CONTACT
								</NavLink>
								<span className="d-none d-lg-inline px-lg-2 text-secondary">|</span>
							</li>
							<li className="text-center mb-4 mb-lg-0">
								<NavLink className="text-secondary" to="/conditions-generales-utilisation">
									conditions générales d&apos;utilisation
								</NavLink>
								<span className="d-none d-lg-inline px-lg-2 text-secondary">|</span>

							</li>
							<li className="text-center mb-4 mb-lg-0">
								<NavLink className="text-secondary" to="/mentions-legales">
									MENTIONS LÉGALES
								</NavLink>
								<span className="d-none d-lg-inline px-lg-2 text-secondary">|</span>

							</li>
							<li className="text-center mb-4 mb-lg-0">
								<NavLink className="text-secondary" to="/politique-cookies">
									Politique de cookies
								</NavLink>
							</li>
						</ul>
					</Col>
					<Col className="col-logo col-12 my-5">
						<Image
							alt="CAISSE D'EPARGNE COTE D'AZUR"
							className="logoCE mx-auto d-block"
							src={logoCE}
							//webp={logoCEP}
						/>
					</Col>
				</Row>
			</Container>
		</footer>
	);
};

export default Footer;
