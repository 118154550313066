import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Carousel from '../../../Carousel/carousel';

import './PropertyLatest.scss';

const { REACT_APP_AWS_S3_URL } = process.env;


const ScreenSectionPropertyLatest = ({ className, properties }) => {
	const descMaxLength = 200;
	const items = properties.map(p => ({
		altText: p.name,
		caption: `${p.description.substr(0, descMaxLength)}${p.description.length > descMaxLength ? '...' : ''}`,
		plus: `/bien/${p.name}/p/${p.uuid}`,
		src: p.files && (`${REACT_APP_AWS_S3_URL}${p.files[0].name}`),
	}));
	// get Three random items
	/*
	const randomThreeItems = [];
	while (items.length > 0 && randomThreeItems.length < 3) {
		const cut = Math.floor(Math.random() * items.length);
		const cutted = items.splice(cut, 1);
		randomThreeItems.push(cutted[0]);
	}*/

	return (
		<Carousel
			className={classNames(className, 'ScreenSectionPropertyLatest')}
			items={items}
		/>
	);
};


ScreenSectionPropertyLatest.propTypes = {
	className: PropTypes.string,
	properties: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

ScreenSectionPropertyLatest.defaultProps = {
	className: '',
};

export default React.memo(ScreenSectionPropertyLatest);
