import PropTypes from 'prop-types';
import React from 'react';

import './Image.scss';

const ScreenHeaderImage = ({ className }) => (
	<div className={`ScreenHeaderImage ${className}`} />
);

ScreenHeaderImage.propTypes = {
	className: PropTypes.string.isRequired,
};
export default React.memo(ScreenHeaderImage);
