import React from 'react';

import PolitiqueCookies from '@cecaz-immo/ui/dist/components/legal/politique-cookies';

import './legal.scss';

const {
	REACT_APP_EMAIL_LEGAL,
	REACT_APP_URL,
	REACT_APP_WWW,
} = process.env;

export default () => (
	<PolitiqueCookies
		appLegalEmail={REACT_APP_EMAIL_LEGAL}
		appUrl={REACT_APP_URL}
		appWWW={REACT_APP_WWW}
	/>
);
