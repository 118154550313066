/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React from 'react';

import LegalArticle from '@cecaz-immo/ui/dist/components/legal/article';
import LegalSection from '@cecaz-immo/ui/dist/components/legal/section';
import LegalTable from '@cecaz-immo/ui/dist/components/legal/table';

const LegalMentions = ({ appEmailPublic, appWWW }) => (
	<LegalSection>
		<span className="text-center">{appWWW}</span>
		<h1 className="section-title">Mentions légales</h1>
		<LegalArticle>
			<h2>EDITEUR DU SITE</h2>
			<p>
				Ce site est édité par la Caisse d’Epargne et de
				Prévoyance Côte d’Azur, ci-après « l’éditeur »
			</p>
			<LegalTable>
				<tr>
					<th scope="row">Raison sociale :</th>
					<td>
						Caisse d’Epargne et de Prévoyance Côte d’Azur,
						société anonyme coopérative à directoire et
						conseil d’orientation et de surveillance,
						régie par les articles L.512-85 et suivants
						du Code monétaire et financier – Capital
						social 515.033.520 euros – Siège social 455,
						promenade des Anglais, 06200 Nice – 384 402 871 RCS NICE –
						Intermédiaire d’assurance, immatriculé à l’ORIAS sous le n° 07 002 199.
						<br />
						Titulaire de la carte professionnelle Transactions sur immeubles et fonds de commerce, sans perception d’effets ou valeurs, n° CPI 0605 2017 000 019 152 délivrée par la CCI Nice-Côte d’Azur, garantie par la Compagnie Européenne de Garanties et Cautions, 16 rue Hoche, Tour KUPKA B, TSA 39999, 92919 LA DEFENSE Cedex. Adresse postale : CS 3297 06205 Nice Cedex 3.
					</td>
				</tr>
				<tr>
					<th scope="row">Forme sociale :</th>
					<td>Société anonyme coopérative à directoire et conseil
					d’orientation et de surveillance
					</td>
				</tr>
				<tr>
					<th scope="row">Siège social :</th>
					<td>455, promenade des Anglais, 06200 Nice.</td>
				</tr>
				<tr>
					<th scope="row">N° SIREN :</th>
					<td>384402871</td>
				</tr>
				<tr>
					<th scope="row">N° ind. d’ident. Intracommunautaire : </th>
					<td>FR6938440287100543</td>
				</tr>
				<tr>
					<th scope="row">Directeur de publication :</th>
					<td>Claude VALADE</td>
				</tr>
				<tr>
					<th scope="row">Contact :</th>
					<td>
						{appEmailPublic}
						<br />
						04 93 18 40 00
					</td>
				</tr>
				<tr>
					<th scope="row">Hébergeur</th>
					<td>Dénomination : Amazon Web Services - Paris <br />
						Adresse : 31 place des Corolles, 92098 PARIS – LA DEFENSE Cedex
					</td>
				</tr>
				<tr>
					<th scope="row">Identifiant unique REP Papiers</th>
					<td>FR232581_03FWUB (BPCE – SIRET 493 455 042).</td>
				</tr>
			</LegalTable>
			<p>
				L’Editeur est un établissement de crédit de droit
				français agréé en qualité de banque prestataire
				de services d’investissement, conformément
				aux dispositions du Code monétaire et financier et
				contrôlée par la Banque Centrale Européenne
				(Sonnemannstrasse 20, D-60314 Frankfurt-am-Main, Allemagne).<br />
				L’Editeur est adhérent auprès de la FBF - Fédération Bancaire Française,
				organisation professionnelle représentative de la profession bancaire.
				En matière de produits d’assurance,
				l’Editeur agit en qualité d’intermédiaire en assurance immatriculé à
				l’ORIAS sous le n°07 002 199
			</p>
			<p>
				Les produits et services proposés sur ce site sont fournis par plusieurs
				promoteurs ou professionnels de l’immobilier disposant des autorisations
				nécessaires pour exercer leur activité et exerçant en conformité avec les
				règles professionnelles applicable à leur profession.
				Pour tout renseignement concernant ces fournisseurs vous pouvez
				adresser votre demande à : {appEmailPublic}.
			</p>
			<p>
				Suite à l’accord de coopération conclu le 24 décembre 2020
				entre le Royaume Uni et l’Union européenne,
				tous les produits et services proposés sur notre site internet ne sont pas destinés aux résidents du Royaume Uni.
			</p>
			<p>Tous les produits et les services proposés sur le site internet ne sont pas tous destinés aux non-résidents</p>
		</LegalArticle>
	</LegalSection>
);

LegalMentions.propTypes = {
	appEmailPublic: PropTypes.string.isRequired,
	appWWW: PropTypes.string.isRequired,
};

export default React.memo(LegalMentions);
