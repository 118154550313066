import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'reactstrap';

import './Title.scss';

const ScreenSectionTitle = ({ text, children }) => (
	<Row className="ScreenSectionTitle mb-4">
		<Col className="ScreenSectionTitle-title py-4 mb-3 mx-auto" tag="h2" xs="12" md="7">
			{text || children}
		</Col>
	</Row>
);

ScreenSectionTitle.propTypes = {
	children: PropTypes.node,
	text: PropTypes.string,
};

ScreenSectionTitle.defaultProps = {
	text: '',
	children: null,
};

export default React.memo(ScreenSectionTitle);
