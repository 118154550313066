import React from 'react';
import PropTypes from 'prop-types';

import { Col } from 'reactstrap';
import './Left.scss';

const ScreenColLeft = ({ children }) => (
	<Col className="ScreenColLeft" xs="12" lg="5">
		{children}
	</Col>
);

ScreenColLeft.propTypes = {
	children: PropTypes.node.isRequired,
};

export default React.memo(ScreenColLeft);
