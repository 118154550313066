import { FormattedMessage as FM } from 'react-intl';
import Helmet from 'react-helmet';
import React from 'react';
import { Row } from 'reactstrap';

import Screen from '../components/Screen/Screen';
import ScreenHeader from '../components/Screen/Header/ScreenHeader';
import ScreenHeaderImage from '../components/Screen/Header/Image/Image';
import ScreenHeaderTitle from '../components/Screen/Header/Title/Title';

import ScreenSection from '../components/Screen/Section/ScreenSection';
import ScreenSectionForYouLinks from '../components/Screen/Section/ForYouLinks/ForYouLinks';
import ScreenSectionSubtitle from '../components/Screen/Section/Subtitle/Subtitle';
import ScreenSectionTitle from '../components/Screen/Section/Title/Title';

import ScreenColLeft from '../components/Screen/Col/Left';
import ScreenColRight from '../components/Screen/Col/Right';

import './Real-estate-professionals.scss';
import ScreenSectionContact from '../components/Screen/Section/Contact/Contact';
import IndexToolTip from '../components/LegalNote/IndexToolTip/IndexToolTip';
import AsteriskFooter from '../components/LegalNote/AsteriskFooter/AsteriskFooter';


class RealEstateProfessionals extends React.PureComponent {
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		return (
			<Screen className="RealEstateProfessionals">
				<Helmet>
					<title>Luxury properties – Professionnels de l’immobilier</title>
				</Helmet>
				<ScreenHeader>
					<ScreenHeaderImage className="realEstate" />
					<ScreenHeaderTitle
						theme="gold"
						colLeft={(
							<FM
								id="realEstatePro.title"
								defaultMessage="Mobiliser nos{br}métiers de{br}l'immobilier de{br}prestige pour{br}vous{br}<strong>accompagner</strong>"
								values={{
									br: <br />,
									strong: (...chunks) => <strong>{chunks}</strong>,
								}}
							/>
						)}
						colRight={(
							<FM
								id="realEstatePro.subtitle"
								tagName="p"
								defaultMessage="Le Centre d’Affaires Luxury Properties répond aux besoins de l'ensemble des intervenants, spécialisés dans
								l'immobilier de luxe (maison de luxe, appartement de luxe ou villas de luxe) en France."
							/>
						)}
					/>
				</ScreenHeader>
				<ScreenSection>
					<ScreenSectionTitle>
						<FM
							id="realEstatePro.foryou.title"
							defaultMessage="<span>pour vous, professionnels de l’immobilier de luxe :</span>"
							values={{
								span: (...chunks) => <span className="text-uppercase">{chunks}</span>,
							}}
						/>
					</ScreenSectionTitle>

					<Row className="row justify-content-around text-justify">
						<ScreenColLeft>
							<FM
								id="realEstatePro.foryou.left"
								defaultMessage="<strong>des solutions dédiées par le centre d'affaires luxury properties :</strong>{br} promoteurs, constructeurs, aménageurs, investisseurs,
								marchands de bien en immobilier de luxe... Le Centre d’Affaires Luxury Properties reste à l’écoute des experts immobiliers."
								values={{
									br: <br />,
									strong: (...chunks) => <strong className="text-uppercase">{chunks}</strong>,
								}}
							/>
						</ScreenColLeft>
						<ScreenColRight>
							<ScreenSectionSubtitle>
								<FM
									id="realEstatePro.foryou.right-1"
									defaultMessage={'<h3>&gt; Des financements{tip} :</h3>'
										+ '<ul>'
										+ '<li key="1">adaptés aux activités de construction et de rénovation immobilière de demeures de prestige.</li>'
										+ '<li key="2">adaptés aux investisseurs professionnels et aux sociétés foncières,souhaitant réaliser un placement dans des propriétés de luxe ou de prestige en France.</li>'
										+ '</ul>'}
									values={{
										h3: (...chunks) => <h3>{chunks}</h3>,
										ul: (...chunks) => <ul className="font-weight-bold">{chunks}</ul>,
										li: (...chunks) => <li>{chunks}</li>,
										tip: <IndexToolTip num="1" id="financements" />,
									}}
								/>
							</ScreenSectionSubtitle>
							<ScreenSectionSubtitle>
								<FM
									id="realEstatePro.foryou.right-2"
									defaultMessage="<h3>&gt; L&apos;aide à la commercialisation de vos projets</h3>"
									values={{
										h3: (...chunks) => <h3>{chunks}</h3>,
									}}
								/>
							</ScreenSectionSubtitle>
							<ScreenSectionSubtitle>
								<FM
									id="realEstatePro.foryou.right-3"
									defaultMessage={
										'<h3>&gt; Des services d’exception au quotidien :</h3>'
										+ '<p>Un service <strong>de banque à distance</strong> qui	sécurise le suivi de vos flux.</p>'
									}
									values={{
										h3: (...chunks) => <h3>{chunks}</h3>,
										p: (...chunks) => <p>{chunks}</p>,
										strong: (...chunks) => <strong>{chunks}</strong>,
									}}
								/>
							</ScreenSectionSubtitle>
						</ScreenColRight>
					</Row>

					<Row className="infoPlus border-top border-secondary justify-content-around pt-5 text-justify">
						<ScreenColLeft>
							<FM
								id="realEstatePro.foryou.leftB"
								defaultMessage="<strong>un accompagnement actif pour les spécialistes de l’immobilier haut de gamme</strong>"
								values={{
									strong: (...chunks) => <strong className="text-uppercase">{chunks}</strong>,
								}}
							/>
						</ScreenColLeft>
						<ScreenColRight>
							<ScreenSectionSubtitle>
								<FM
									id="realEstatePro.foryou.rightB-1"
									defaultMessage={
										'<h3>&gt; Prise de participation{tip} :</h3>'
										+ '<p>'
										+ 'Afin de s’associer concrètement à l’avancement de vos projets immobiliers de luxe, La Caisse d’Epargne Côte d’Azur peut prendre des participations dans la structure juridique de votre programme immobilier.'
										+ '</p>'
									}
									values={{
										h3: (...chunks) => <h3>{chunks}</h3>,
										p: (...chunks) => <p>{chunks}</p>,
										tip: <IndexToolTip num="2" id="prise de participation" />,
									}}
								/>
							</ScreenSectionSubtitle>
						</ScreenColRight>
					</Row>
				</ScreenSection>
				<ScreenSectionContact />
				<ScreenSectionForYouLinks futur={process.env.REACT_APP_SHOW_PROPERTIES !== 'true'} />
				<AsteriskFooter page="professionnelImmobilier" />
			</Screen>
		);
	}
}
export default RealEstateProfessionals;
