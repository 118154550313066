import { request, checkNotFoundError } from '@cecaz-immo/app-client/dist/lib/request';

const { REACT_APP_API_URL } = process.env;

export const SEARCH_PROGRAMME_DEBUT_ACTION = 'SEARCH_PROGRAMME_DEBUT_ACTION';
export const SEARCH_PROGRAMME_RESULT_ACTION = 'SEARCH_PROGRAMME_RESULT_ACTION';
export const GET_CURRENT_PROGRAMME_ACTION = 'GET_CURRENT_PROGRAMME_ACTION';
export const GET_LAST_PROGRAMMES_ACTION = 'GET_LAST_PROGRAMMES_ACTION';
export const GET_RANDOM_PROGRAMMES_ACTION = 'GET_RANDOM_PROGRAMMES_ACTION';
export const GET_OTHER_PROGRAMMES_ACTION = 'GET_OTHER_PROGRAMMES_ACTION';

/**
	Calculate min/max of prices and rooms.
*/
export const calculatePricesAndRoomsProgramme = (programme) => {
	if (!programme || !programme.lots || !programme.lots.length) return programme;

	programme.lots.forEach((lot, i) => {
		if (i === 0) {
			programme.minPrice = lot.minPrice;
			programme.maxPrice = lot.maxPrice;
			programme.minRooms = lot.rooms;
			programme.maxRooms = lot.rooms;
			return;
		}

		if (lot.minPrice < programme.minPrice) programme.minPrice = lot.minPrice;
		if (lot.maxPrice > programme.maxPrice) programme.maxPrice = lot.maxPrice;
		if (lot.rooms < programme.minRooms) programme.minRooms = lot.rooms;
		if (lot.rooms > programme.maxRooms) programme.maxRooms = lot.rooms;
	});

	return programme;
};

/**
	Sort files of programme by attribute "order"
 */
export const sortFilesFromProgramme = (programme) => {
	if (!programme || !programme.files || !programme.files.length) {
		return;
	}

	programme.files.sort((a, b) => a.order - b.order);
};

/**
 	Transform programme
 */
export const transformProgramme = (programme) => {
	sortFilesFromProgramme(programme);
};

// ---- SEARCH PROGRAMME
const searchProgrammeDebutAction = () => ({
	type: SEARCH_PROGRAMME_DEBUT_ACTION,
});

const searchProgrammeResultAction = (programmes) => ({
	type: SEARCH_PROGRAMME_RESULT_ACTION,
	programmes,
});

const partnerNameToId = (name) => {
	const id = process.env[`REACT_APP_PARTNER_${name.toUpperCase()}`];
	return id || undefined;
};

export const searchProgrammeWithFetch = (search, fetchMethod = fetch) => (dispatch) => {
	dispatch(searchProgrammeDebutAction());

	let params = '';
	const data = {
		//adminId: search.adminId,
		locationZone: search.locationZone,
		//south: search.location && search.location.bounds && search.location.bounds.south,
		//west: search.location && search.location.bounds && search.location.bounds.west,
		//north: search.location && search.location.bounds && search.location.bounds.north,
		//east: search.location && search.location.bounds && search.location.bounds.east,
		//minRooms: search.minRooms,
		//maxRooms: search.maxRooms,
		//minPrice: search.minPrice,
		//maxPrice: search.maxPrice,
		//developer: search.developer,
		//programme: search.programme,
		developerId: search.partner && partnerNameToId(search.partner),
		status: search.status,
	};

	if (data) {
		params += '?';

		Object.keys(data).forEach((property) => {
			if (!data[property]) {
				return;
			}
			params += `${property}=${data[property]}&`;
		});
	}

	return fetchMethod(
		`${REACT_APP_API_URL}/rechercher${params}`,
		{
			headers: {
				'Cache-Control': 'no-cache',
			},
		},
	)
		.then((resp) => resp.json())
		.then((results) => {
			results.forEach((p) => {
				transformProgramme(p);
			});
			dispatch(searchProgrammeResultAction(results));
		})
		.catch(() => { dispatch(searchProgrammeResultAction([])); });
};

export const searchProgramme = (search) => searchProgrammeWithFetch(search);

// ---- GET CURRENT PROGRAMME
const getCurrentProgrammeAction = (programme) => ({
	type: GET_CURRENT_PROGRAMME_ACTION,
	programme,
});

export const getCurrentProgramme = (uuid) => (dispatch) => fetch(
	`${REACT_APP_API_URL}/programme/${uuid}`,
	{
		headers: {
			'Cache-Control': 'no-cache',
		},
	},
)
	.then((resp) => resp.json())
	.then((result) => {
		transformProgramme(result);
		dispatch(getCurrentProgrammeAction(result));
	});

// ----  SET LAST PROGRAMMES
const getLastProgrammesAction = (programmes) => ({
	type: GET_LAST_PROGRAMMES_ACTION,
	programmes,
});
const getRandomProgrammesAction = (programmes) => ({
	type: GET_RANDOM_PROGRAMMES_ACTION,
	programmes,
});

export const getLastProgrammes = () => (dispatch) => fetch(
	`${REACT_APP_API_URL}/programmes/derniers`,
	{
		headers: {
			'Cache-Control': 'no-cache',
		},
	},
)
	.then((resp) => resp.json())
	.then((results) => {
		results.forEach((item) => {
			transformProgramme(item);
		});
		dispatch(getLastProgrammesAction(results));
	});

export const getRandomProgrammes = () => (dispatch) => fetch(
	`${REACT_APP_API_URL}/programmes/random`,
	{
		headers: {
			'Cache-Control': 'no-cache',
		},
	},
)
	.then((resp) => resp.json())
	.then((results) => {
		if (results.length) {
			results.forEach((item) => {
				transformProgramme(item);
			});
			dispatch(getRandomProgrammesAction(results));
		}
	})
	.catch((e) => {
		console.log(e);
	});

// ----  SET OTHER PROGRAMMES
const getOtherProgrammesAction = (programmes) => ({
	type: GET_OTHER_PROGRAMMES_ACTION,
	programmes,
});

export const getOtherProgrammes = (uuid) => (dispatch) => request(
	`${REACT_APP_API_URL}/programme/${uuid}/autres`,
	{
		headers: {
			'Cache-Control': 'no-cache',
		},
	},
)
	.then((resp) => resp.json())
	.then((results) => {
		results.forEach((item) => {
			transformProgramme(item);
		});
		dispatch(getOtherProgrammesAction(results));
	})
	.catch(checkNotFoundError);
